import React, { useEffect, useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import A1 from "../../../assets/profile_avatars/A1.png";
import A2 from "../../../assets/profile_avatars/A2.png";
import A3 from "../../../assets/profile_avatars/A3.png";
import A4 from "../../../assets/profile_avatars/A4.png";
import A5 from "../../../assets/profile_avatars/A5.png";
import A6 from "../../../assets/profile_avatars/A6.png";
import "./teamProjects.css";
import AXIOS from "../../../services/axios";
import ReassigningProject from "../../teamProjectPopups/reassigningProject/reassigningProject";
import { formatIndianNumber } from "../../../services/reusableCodes";
import ViewVersionsInProfile from "../../teamProjectPopups/viewVersionsInProfile/viewVersionsInProfile";

function TeamProjects() {
  const [isPopup, setIsPopUp] = useState({
    reassigning: {
      projectId: "",
      projectName: "",
      assignedUser: "",
    },
    viewVersions: false,
  });
  const [projects, setProjects] = useState("");
  const TeamMembersImg = {
    A1: A1,
    A2: A2,
    A3: A3,
    A4: A4,
    A5: A5,
    A6: A6,
  };
  const [searchTerm, setSearchTerm] = useState("");

  

  const fetchTeamProjectDetails = async () => {
    try {
      const response = await AXIOS.get(
        "/organization/get-team-project-details",
        {
          withCredentials: true, // Ensure cookies are sent with the request
        }
      );

      if (response.status === 200) {
        setProjects(response.data.projects);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTeamProjectDetails();
  }, []);

  // Filter projects based on the search term
  const filteredProjects = projects && projects.filter((project) =>
    project.project_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <section className="profile-details team-members-details-container team-project-details-container">
      {isPopup.reassigning.projectId && (
        <ReassigningProject
          setIsPopUp={setIsPopUp}
          reassigning={isPopup.reassigning}
          TeamMembersImg={TeamMembersImg}
          fetchTeamProjectDetails={fetchTeamProjectDetails}
        />
      )}

      {isPopup.viewVersions && (
        <ViewVersionsInProfile
          setIsPopUp={setIsPopUp}
          project={isPopup.viewVersions}
        />
      )}

      <div className="order-history">
        <div className="order-search-bar team-members-search-bar">
          <IoSearchOutline className="order-search-icon" />
          <input
            type="text"
            placeholder="Search by Project Name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      <section className="profile-project-details-section">
        {projects && filteredProjects.length > 0 ?
          filteredProjects.map((project, index) => (
            <div key={index} className="member-project-details">
              <div className="profile-project-assigned-section">
                <div className="project-assigned-user-section">
                  <img
                    src={
                      TeamMembersImg[project.assigned_user.profile_picture] ||
                      A1
                    }
                    alt="Assigned Team Member"
                    className="project-aassigned-member-img"
                  />
                  <p className="project-aassigned-to">
                    Assigned to <p>{project.assigned_user.name}</p>
                  </p>
                </div>
                <div className="project-created-date">
                  <p className="project-date-label">Created On</p>
                  <p className="project-date">{project.created_on}</p>
                  <button
                    className="profile-project-assign-button"
                    onClick={() =>
                      setIsPopUp((prev) => ({
                        ...prev,
                        reassigning: {
                          ...prev.reassigning,
                          projectId: project.project_id,
                          projectName: project.project_name,
                          assignedUser: project.assigned_user.name,
                        },
                      }))
                    }
                  >
                    Re-Assign
                  </button>
                </div>
              </div>

              <div className="profile-project-info">
                <h2 className="profile-project-title">
                  {project.project_name}
                </h2>
                <div className="profile-project-details">
                  <div className="profile-project-location-id">
                    <p className="profile-project-location">
                      {project.project_location}
                    </p>
                    <p className="profile-project-id">Project ID: {project.project_id}</p>
                  </div>
                  <div className="project-created-date">
                    <p className="project-date-label">Quotation Value</p>
                    <h2 className="profile-project-budget">
                      {"₹" +
                        formatIndianNumber(
                          project.last_version.quotation_price
                        )}
                    </h2>
                  </div>
                </div>
              </div>

              <div className="profile-project-details interioverse-project-details">
                <div className="project-created-date">
                  <p className="project-date-label">Hire Interioverse at</p>
                  <h2 className="profile-project-budget">
                    {"₹" +
                      formatIndianNumber(Math.ceil(project.last_version.interioverse_price * 0.93)
                        
                      )}
                  </h2>
                </div>
                <div>
                  <button
                    className="profile-project-assign-button"
                    onClick={() =>
                      setIsPopUp((prev) => ({
                        ...prev,
                        viewVersions: project,
                      }))
                    }
                  >
                    View Versions
                  </button>
                </div>
              </div>
            </div>
          )):
          <p className="profile-project-not-found">Project Not Found</p>
          }
      </section>
    </section>
  );
}

export default TeamProjects;
