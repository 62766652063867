import React, { useEffect, useState } from "react";
import { GoPlus } from "react-icons/go";
import { MdDelete } from "react-icons/md";
import landingPageImg from "../../assets/Landing_page_picture.jpg";
import "./baseTemplateCity.css";
import AXIOS from "../../services/axios";
import { useNavigate } from "react-router-dom";

function BaseTemplateCity() {
  const [cities, setCities] = useState({
    cityNames: [],
    isCityDelete: "",
    isAddCity: false,
    newCity: {
      name: "",
      copyName: "",
      otherName: "",
    },
    error: false,
  });
  const [citiesList, setCitiesList] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const navigate = useNavigate();

  const fetchTemplateCityNames = async () => {
    try {
      const response = await AXIOS.get(
        "/organization/get-template-city-names"
      );
      if (response.status === 200) {
        setCities((prev) => ({
          ...prev,
          cityNames: response.data.cityNames,
        }));
      }
    } catch (err) {
      console.error(err);
    }
  };

  const fetchCities = async () => {
    try {
      const response = await AXIOS.get("/all-cities-list");
      if (response.status === 200) {
        setCitiesList(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTemplateCityNames();
    fetchCities();
  }, []);

  const handleCityTemplate = async (e) => {
    e.preventDefault();

    try {
      const { name, copyName, otherName } = cities.newCity;

      if (isDropdownVisible) {
        return;
      }

      if (!name) {
        setCities((prev) => ({
          ...prev,
          error: "empty-name",
        }));
        return;
      }

      // Determine the final city name to be used
      let cityName = otherName?.trim() ? otherName.trim() : name.trim();

      if (cityName === "Bengaluru/Bangalore") {
        cityName = "Bengaluru";
      }

      // Check if the city already exists in the list
      if (
        cities.cityNames.some(
          (existingCity) =>
            (existingCity.toLowerCase() === "bengaluru" &&
              (cityName.toLowerCase() === "bangalore" ||
                cityName.toLowerCase() === "bengaluru")) ||
            existingCity.toLowerCase() === cityName.toLowerCase()
        )
      ) {
        setCities((prev) => ({
          ...prev,
          error: "duplicate-name",
        }));
        return;
      }

      // Send a POST request to save the city template
      const response = await AXIOS.post(
        `/organization/save-base-city-template`,
        {
          city: cityName,
          copyCity: copyName,
        }
      );

      // If successful, fetch updated city names and reset the form
      if (response.status === 200) {
        await fetchTemplateCityNames();
        setCities((prev) => ({
          ...prev,
          isAddCity: false,
          newCity: {
            name: "",
            copyName: "",
            otherName: "",
          },
          error: false,
        }));
        setDropdownVisible(false);
      }
    } catch (error) {
      console.error(
        "Error saving data:",
        error?.response?.data || error.message
      );
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setCities((prev) => ({
      ...prev,
      newCity: {
        ...prev.newCity,
        [name]: value,
        // Clear otherName only if the input being changed is 'name'
        ...(name === "name" ? { otherName: "" } : {}),
      },
      error: false,
    }));

    // Control the dropdown visibility based on the 'name' input
    if (name === "name") {
      setDropdownVisible(value.trim().length > 1);
    }
  };

  async function handleDeleteCity(e, cityName) {
    e.preventDefault();
    try {
      const response = await AXIOS.delete(
        "/organization/delete-city-template",
        {
          data: { city: cityName },
        }
      );

      if (response.status === 200) {
        fetchTemplateCityNames();
        setCities((prev) => ({
          ...prev,
          isCityDelete: "",
        }));
      }
    } catch (error) {
      console.error("Error deleting city template:", error);
    }
  }

  useEffect(() => {
    if (cities.cityNames.length > 0 && citiesList.length > 0) {
      const filtered = citiesList
        .filter(
          (city) =>
            // Check if the city is not already in cities.cityNames
            !cities.cityNames.some(
              (existingCity) =>
                (existingCity.toLowerCase() === "bengaluru" &&
                  city.toLowerCase() === "bengaluru/bangalore") ||
                existingCity.toLowerCase() === city.toLowerCase()
            ) &&
            // Check if the city includes the newCity input
            city.toLowerCase().includes(cities.newCity.name.toLowerCase())
        )
        .slice(0, 5); // Limit to 5 suggestions

      setFilteredCities(filtered);
    }
  }, [cities.cityNames, citiesList, cities.newCity.name]);

  return (
    <section className="city-template-container">
      <figure>
        <img
          src={landingPageImg}
          alt="Cover img"
          className="partner-cover-img"
        />
      </figure>

      {/* to add city name  */}
      {cities.isAddCity && (
        <section className="version-delete-popup">
          <div className="version-delete-container">
            <div className="version-delete-content">
              <div className="version-input-container">
                <label className="version-input-label">Enter City name</label>
                <div
                  className={`${
                    cities.newCity.name === "Other"
                      ? "city-template-input-container"
                      : ""
                  }`}
                >
                  <input
                    type="text"
                    className={`version-input-field ${
                      cities.newCity.name === "Other"
                        ? "city-template-field"
                        : ""
                    }`}
                    placeholder="City name"
                    required
                    name="name"
                    value={cities.newCity.name}
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                  {isDropdownVisible && cities.newCity.name !== "Other" && (
                    <ul
                      className="dropdown-list parter-dropdown-list"
                      style={{ marginTop: "0" }}
                    >
                      {filteredCities.length > 0 ? (
                        filteredCities.map((city, index) => (
                          <li
                            key={index}
                            className="dropdown-item"
                            onClick={() => {
                              setCities((prev) => ({
                                ...prev,
                                newCity: {
                                  ...prev.newCity,
                                  name: city,
                                },
                              }));
                              setDropdownVisible(false);
                            }}
                          >
                            {city}
                          </li>
                        ))
                      ) : (
                        <li
                          className="dropdown-item"
                          onClick={() => {
                            setCities((prev) => ({
                              ...prev,
                              newCity: {
                                ...prev.newCity,
                                name: "Other",
                              },
                            }));
                            setDropdownVisible(false);
                          }}
                        >
                          Other
                        </li>
                      )}
                    </ul>
                  )}
                  {cities.newCity.name === "Other" && (
                    <input
                      type="text"
                      className="version-input-field"
                      placeholder="City name"
                      required
                      name="otherName"
                      value={cities.newCity.otherName}
                      onChange={handleInputChange}
                      autoComplete="off"
                    />
                  )}
                </div>

                {cities.error === "empty-name" && (
                  <p className="error version-error">Please enter city name</p>
                )}

                {cities.error === "duplicate-name" && (
                  <p className="error version-error">
                    City name already exists
                  </p>
                )}
              </div>

              {cities.cityNames.length > 1 && (
                <div className="version-list-container">
                  <p className="version-list-title">
                    Which city template would you like to copy
                  </p>
                  <div className="version-list-items">
                    {cities.cityNames.map((city, index) =>
                      city !== "base template" ? (
                        <p
                          key={index}
                          className={`version-list-item ${
                            cities.newCity.copyName === city
                              ? "version-list-item-selected"
                              : ""
                          } `}
                          onClick={() =>
                            setCities((prev) => ({
                              ...prev,
                              newCity: {
                                ...prev.newCity,
                                copyName: city,
                              },
                            }))
                          }
                        >
                          {city}
                        </p>
                      ) : null
                    )}
                  </div>
                </div>
              )}

              <div className="version-button-group">
                <button
                  className="version-delete-button"
                  onClick={handleCityTemplate}
                >
                  Create
                </button>
                <button
                  className="version-cancel-button"
                  onClick={() => {
                    setCities({
                      ...cities,
                      isAddCity: false,
                      error: false,
                      newCity: {
                        name: "",
                        copyName: "",
                      },
                    });
                    setDropdownVisible(false);
                  }}
                >
                  Cancel
                </button>
              </div>
              {isDropdownVisible && (
                <p className="error" style={{ textAlign: "center" }}>
                  Please select city from the dropdown
                </p>
              )}
            </div>
          </div>
        </section>
      )}

      {/* to delete city name  */}
      {cities.isCityDelete && (
        <section className="version-delete-popup">
          <div className="version-delete-container">
            <div className="version-delete-content">
              <p className="version-delete-label">{`Do you want to delete ${cities.isCityDelete} Template?`}</p>
              <div className="version-button-group">
                <button
                  className="version-delete-button"
                  onClick={(e) => handleDeleteCity(e, cities.isCityDelete)}
                >
                  Delete
                </button>
                <button
                  className="version-cancel-button"
                  onClick={() =>
                    setCities((prev) => ({
                      ...prev,
                      isCityDelete: "",
                    }))
                  }
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </section>
      )}

      <section className="city-template-section">
        <h1 className="city-template-title">My Templates </h1>

        <section className="city-template-content">
          <ul className="city-template-list">
            <li
              className="new-city-template city-template-item"
              aria-label="New Quotation"
              onClick={() =>
                setCities({
                  ...cities,
                  isAddCity: true,
                  error: false,
                })
              }
            >
              <div className="add-new new-city-button">
                <GoPlus className="new-city-icon" />
              </div>
              <p className="new-city-text">New City Template</p>
            </li>

            <li
              className="city-template-item hovered-template"
              onClick={() => navigate(`/quotation-template`)}
            >
              <span className="city-template-name">Base Template</span>
            </li>

            {cities &&
              cities.cityNames.map((cityName, index) =>
                cityName !== "base template" ? (
                  <li
                    key={index}
                    className="city-template-item hovered-template"
                  >
                    <MdDelete
                      className="delete-city-template-icon"
                      onClick={() =>
                        setCities((prev) => ({
                          ...prev,
                          isCityDelete: cityName,
                        }))
                      }
                    />
                    <span
                      className="city-template-name"
                      onClick={() =>
                        navigate(`/quotation-template/${cityName}`)
                      }
                    >
                      {cityName}
                    </span>
                  </li>
                ) : null
              )}
          </ul>
        </section>
      </section>
    </section>
  );
}

export default BaseTemplateCity;
