import React, { useEffect, useRef, useState } from "react";
import "./finishes-types-popup.css";
import { IoMdClose } from "react-icons/io";
import AXIOS from "../../services/axios";
import { fetchUserRates } from "../../services/reusableCodes";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useSelector } from "react-redux";

function FinishesTypesPopup({ setFinishes, tableData, fetchQuotations }) {
  const [rates, setRates] = useState([]);
  const [categories, setCategories] = useState([]);
  const [hoveredType, setHoveredType] = useState(null);
  const [editType, setEditType] = useState(null);
  const [userEditType, setUserEditType] = useState(null);
  const [isSaved, setIsSaved] = useState(false);
  const [addPopup, setAddPopup] = useState(false);
  const tdRef = useRef(null);
  const usereditDescrptionRef = useRef(null);
  const [userCategories, setUserCategories] = useState([]);
  const [userCategoryData, setUserCategoryData] = useState({
    category_id: "",
    finish_id: "",
    type_id: "",
    category: "",
    finish: "",
    type: "",
    unit: "",
    description: "",
    rate: "",
  });
  const [suggestions, setSuggestions] = useState({
    categories: [],
    finishes: [],
    types: [],
  });
  const [deleteCategories, setDeleteCategories] = useState({
    category: "",
    finish: "",
    type: "",
    category_id: "",
    finish_id: "",
    type_id: "",
  });
  const formRef = useRef(null);
  const currentAccountType = useSelector(
    (state) => state.auth.currentAccountType
  );

  const fetchAllQuotations = async () => {
    try {
      const response = await AXIOS.get(`/admin/all-quotations`);
      if (response.status === 200) {
        setCategories(response.data[0].categories);
        fetchUserRates(setRates, setUserCategories, currentAccountType);
      }
    } catch (error) {
      console.error("Error fetching quotations:", error);
    }
  };

  useEffect(() => {
    fetchAllQuotations();
  }, []);

  const handleChange = (category, finish, type, name, value) => {
    setRates((prevRates) => {
      const existingRateIndex = prevRates.findIndex(
        (rate) =>
          rate.category === category &&
          rate.finish === finish &&
          rate.type === type
      );

      if (existingRateIndex !== -1) {
        // Update existing rate
        const updatedRates = [...prevRates];
        updatedRates[existingRateIndex] = {
          ...updatedRates[existingRateIndex],
          [name]: value,
        };
        return updatedRates;
      } else {
        // Add new rate
        return [...prevRates, { category, finish, type, [name]: value }];
      }
    });
  };

  const getRate = (category, finish, type, name) => {
    const rateObj = rates.find(
      (rate) =>
        rate.category.toLowerCase() === category.toLowerCase() &&
        rate.finish.toLowerCase() === finish.toLowerCase() &&
        rate.type.toLowerCase() === type.toLowerCase()
    );

    return rateObj ? rateObj[name] : "";
  };

  const handleSubmitRate = async (e) => {
    e.preventDefault();

    try {
      const transformedRates = rates.reduce((acc, rate) => {
        // Check if there is already an entry for this category in transformedRates
        const existingCategoryIndex = acc.findIndex(
          (item) => item._id === rate.category
        );

        if (existingCategoryIndex !== -1) {
          // Category exists, check if finish exists for this category
          const existingFinishIndex = acc[
            existingCategoryIndex
          ].finishes.findIndex((finish) => finish._id === rate.finish);

          if (existingFinishIndex !== -1) {
            // Finish exists, add type to existing types array
            acc[existingCategoryIndex].finishes[existingFinishIndex].types.push(
              {
                name: rate.type,
                rate: rate.rate,
                description: rate.description,
                widthUnit: rate.widthUnit,
              }
            );
          } else {
            // Finish does not exist, add new finish with type
            acc[existingCategoryIndex].finishes.push({
              name: rate.finish,
              types: [
                {
                  name: rate.type,
                  rate: rate.rate,
                  unit: rate.unit,
                  description: rate.description,
                  widthUnit: rate.widthUnit,
                },
              ],
            });
          }
        } else {
          // Category does not exist, create new category with finish and type
          acc.push({
            name: rate.category,
            finishes: [
              {
                name: rate.finish,
                types: [
                  {
                    name: rate.type,
                    rate: rate.rate,
                    unit: rate.unit,
                    description: rate.description,
                    widthUnit: rate.widthUnit,
                  },
                ],
              },
            ],
          });
        }

        return acc;
      }, []);

      const response = await AXIOS.post(
        `/${
          currentAccountType === "organization" ? "organization" : "quotation"
        }/add-rate`,
        {
          rates: transformedRates,
          userCategories: userCategories,
        }
      );
      if (response.status === 200) {
        fetchUserRates(setRates, setUserCategories, currentAccountType);

        setIsSaved(true);
        tableData();
        fetchQuotations();
        setTimeout(() => {
          setIsSaved(false);
        }, 3000);
      }
    } catch (error) {
      console.error(error); // Using console.error for better error visibility
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if click is outside the <td> with the ref
      if (tdRef.current && !tdRef.current.contains(event.target)) {
        setEditType(null);
      }

      if (
        usereditDescrptionRef.current &&
        !usereditDescrptionRef.current.contains(event.target)
      ) {
        setUserEditType(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCategoryData = (e) => {
    const { name, value } = e.target;

    // Enforce character limit
    if (
      (name === "category" || name === "finish" || name === "type") &&
      value.length > 25
    ) {
      return; // Prevent further input if the length exceeds 25
    }

    // Update the state
    setUserCategoryData((prev) => ({ ...prev, [name]: value }));

    // Handle category suggestions
    if (name === "category") {
      const categorySuggestions = Array.isArray(userCategories)
        ? userCategories
            .filter((cat) =>
              cat?.name?.toLowerCase().includes(value.toLowerCase())
            )
            .map((cat) => cat.name)
        : []; // Fallback to an empty array if userCategories is not valid

      setSuggestions({
        categories: categorySuggestions,
        finishes: [], // Clear finishes when updating category
      });
    }

    // Handle finish suggestions
    if (name === "finish") {
      const finishSuggestions = Array.isArray(userCategories)
        ? userCategories
            .filter(
              (cat) =>
                cat?.name?.toLowerCase() ===
                (userCategoryData?.category?.toLowerCase() || "")
            )
            .flatMap((cat) => cat.finishes || []) // Safely handle finishes array
            .filter((finish) =>
              finish?.name?.toLowerCase().includes(value.toLowerCase())
            )
            .map((finish) => finish.name)
        : []; // Fallback to an empty array if userCategories is not valid

      setSuggestions({
        categories: [], // Clear categories when updating finish
        finishes: finishSuggestions,
      });
    }
  };

  const handleSubmittedCategory = async (e) => {
    e.preventDefault();

    userCategoryData.actionType = addPopup === "new" ? "new" : "edit";

    try {
      const response = await AXIOS.post(
        "/organization/update-organization-category",
        {
          organizationCategoryData: userCategoryData,
        }
      );

      if (response.status === 200) {
        fetchAllQuotations();
        tableData();
        fetchQuotations();
        setUserCategoryData({
          category: "",
          finish: "",
          type: "",
          unit: "",
        });
        setAddPopup(false);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDelete = async () => {
    try {
      // Extract data from state
      const { category_id, finish_id, type_id } = deleteCategories;

      // Make DELETE request with data sent in the 'data' field
      const response = await AXIOS.delete(
        "/organization/delete-organization-category",
        {
          data: { category_id, finish_id, type_id },
        }
      );

      if (response.status === 200) {
        // Clear the state after successful deletion
        setDeleteCategories({
          category: "",
          finish: "",
          type: "",
          category_id: "",
          finish_id: "",
          type_id: "",
        });
        fetchAllQuotations();
      }
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  const handleUserCategoryChange = (
    tableId,
    finishId,
    typeId,
    field,
    value
  ) => {
    setUserCategories((prevCategories) =>
      prevCategories.map((table) => {
        if (table._id === tableId) {
          return {
            ...table,
            finishes: table.finishes.map((finish) => {
              if (finish._id === finishId) {
                return {
                  ...finish,
                  types: finish.types.map((type) => {
                    if (type._id === typeId) {
                      return {
                        ...type,
                        [field]: value, // Update the specified field with the new value
                      };
                    }
                    return type;
                  }),
                };
              }
              return finish;
            }),
          };
        }
        return table;
      })
    );
  };

  return (
    <section className="finishes-types-popup">
      {/* delete popup  */}

      {deleteCategories.category_id && (
        <section className="version-delete-popup">
          <div className="version-delete-container">
            <div className="version-delete-content">
              <p className="version-delete-label">{`Do you want to delete ${
                deleteCategories.type
                  ? deleteCategories.type + " type ?"
                  : deleteCategories.finish
                  ? deleteCategories.finish + " finish ?"
                  : deleteCategories.category + " category ?"
              } `}</p>
              <div className="version-button-group">
                <button
                  className="version-delete-button"
                  onClick={handleDelete}
                >
                  Delete
                </button>
                <button
                  className="version-cancel-button"
                  onClick={() =>
                    setDeleteCategories((prev) => ({
                      ...prev,
                      category: "",
                      finish: "",
                      type: "",
                      category_id: "",
                      finish_id: "",
                      type_id: "",
                    }))
                  }
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </section>
      )}

      <div className="finishes-types-popup-container">
        <div>
          <IoMdClose
            className="finishes-types-popup-icon"
            onClick={() => setFinishes(false)}
          />
        </div>
        <h2 className="finishes-types-popup-title">
          Add your rates here <span>(before GST)</span>
        </h2>

        <div className="finishes-popup-container">
          {categories &&
            categories.map((table, tableIndex) => (
              <div key={tableIndex} className="finishes-popup-category-section">
                <h2 className="finishes-popup-category-name">{table.name}</h2>
                <div className="finishes-popup-finishes-section">
                  {table.finishes.map((finish, finishIndex) => (
                    <table
                      key={finishIndex}
                      className="finishes-popup-finishes-table"
                    >
                      <thead>
                        <tr className="finishes-popup-finishes-row">
                          <th className="finishes-popup-finishe-name">
                            {finish.name}
                          </th>
                          <th className="finishes-popup-rate">Unit</th>
                          <th className="finishes-popup-rate">Rate</th>
                        </tr>
                      </thead>
                      <tbody>
                        {finish.types.map((type, typeIndex) => (
                          <tr
                            className="finishes-popup-types-row"
                            key={typeIndex}
                          >
                            <td className="finishes-popup-type-name finishes-popup-type-width">
                              <p
                                className="finish-type-title finish-popup-type-title"
                                onMouseEnter={() =>
                                  setHoveredType(
                                    `${tableIndex}-${finishIndex}-${typeIndex}`
                                  )
                                }
                                onMouseLeave={() => setHoveredType(null)}
                                onDoubleClick={() =>
                                  setEditType(
                                    `${tableIndex}-${finishIndex}-${typeIndex}`
                                  )
                                }
                              >
                                {type.name}
                              </p>
                              {hoveredType ===
                                `${tableIndex}-${finishIndex}-${typeIndex}` &&
                                type.description &&
                                !editType && (
                                  <p className="finish-type-title-description">
                                    {getRate(
                                      table._id,
                                      finish._id,
                                      type._id,
                                      "description"
                                    )
                                      ? getRate(
                                          table._id,
                                          finish._id,
                                          type._id,
                                          "description"
                                        )
                                      : type.description}
                                  </p>
                                )}
                              {editType ===
                                `${tableIndex}-${finishIndex}-${typeIndex}` &&
                                type.description &&
                                editType && (
                                  <textarea
                                    ref={tdRef}
                                    placeholder="Enter Your description"
                                    value={getRate(
                                      table._id,
                                      finish._id,
                                      type._id,
                                      "description"
                                    )}
                                    onChange={(e) =>
                                      handleChange(
                                        table._id,
                                        finish._id,
                                        type._id,
                                        "description",
                                        e.target.value
                                      )
                                    }
                                    className="finish-type-title-edit-description"
                                    spellCheck="false"
                                  />
                                )}
                            </td>

                            <td className="finishes-popup-type-name">
                              <p className="finish-type-title finish-popup-type-title">
                                {type.unit === "HW"
                                  ? "SFT"
                                  : type.unit === "W"
                                  ? type.widthUnit
                                    ? type.widthUnit
                                    : "RFT"
                                  : type.unit}
                              </p>
                            </td>

                            <td className="finishes-popup-rate-input">
                              <span>
                                {getRate(
                                  table._id,
                                  finish._id,
                                  type._id,
                                  "rate"
                                )
                                  ? "₹"
                                  : ""}
                              </span>
                              <input
                                type="text"
                                value={getRate(
                                  table._id,
                                  finish._id,
                                  type._id,
                                  "rate"
                                )}
                                onChange={(e) =>
                                  handleChange(
                                    table._id,
                                    finish._id,
                                    type._id,
                                    "rate",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ))}
                </div>
              </div>
            ))}

          {currentAccountType === "organization" &&
            userCategories &&
            userCategories.length > 0 && (
              <div>
                <hr />
                <h2 className="added-categories-title">
                  Your Custom Categories
                </h2>
                {userCategories.map((table, tableIndex) => (
                  <div
                    key={tableIndex}
                    className="finishes-popup-category-section"
                  >
                    <h2 className="finishes-popup-category-name">
                      <span>{table.name}</span>
                      <span className="finish-type-edit-icons">
                        <FaEdit
                          className="finish-type-edit-icon category-name-icon"
                          onClick={() => {
                            setAddPopup("edit_category");
                            setUserCategoryData({
                              category: table.name,
                              category_id: table._id,
                              finish_id: table.finishes[0]._id,
                              type_id: table.finishes[0].types[0]._id,
                              finish: table.finishes[0].name,
                              type: table.finishes[0].types[0].name,
                              unit: table.finishes[0].types[0].unit,
                            });
                            formRef.current.scrollIntoView({
                              behavior: "smooth",
                            });
                          }}
                        />
                        <MdDelete
                          className="finish-type-edit-icon category-name-icon"
                          onClick={() =>
                            setDeleteCategories((prev) => ({
                              ...prev,
                              category: table.name,
                              category_id: table._id,
                            }))
                          }
                        />
                      </span>
                    </h2>
                    <div className="finishes-popup-finishes-section">
                      {table.finishes.map((finish, finishIndex) => (
                        <table
                          key={finishIndex}
                          className="finishes-popup-finishes-table"
                        >
                          <thead>
                            <tr className="finishes-popup-finishes-row">
                              <th className="finishes-popup-finishe-name">
                                <span>{finish.name}</span>
                                <span className="finish-type-edit-icons">
                                  <FaEdit
                                    className="finish-type-edit-icon"
                                    onClick={() => {
                                      setAddPopup("edit_finish");
                                      setUserCategoryData({
                                        category: table.name,
                                        finish: finish.name,
                                        type: finish.types[0].name,
                                        category_id: table._id,
                                        finish_id: finish._id,
                                        type_id: finish.types[0]._id,
                                        unit: finish.types[0].unit,
                                      });
                                      formRef.current.scrollIntoView({
                                        behavior: "smooth",
                                      });
                                    }}
                                  />
                                  <MdDelete
                                    className="finish-type-edit-icon"
                                    onClick={() =>
                                      setDeleteCategories((prev) => ({
                                        ...prev,
                                        category: table.name,
                                        category_id: table._id,
                                        finish: finish.name,
                                        finish_id: finish._id,
                                      }))
                                    }
                                  />
                                </span>
                              </th>
                              <th className="finishes-popup-rate">Unit</th>
                              <th className="finishes-popup-rate">Rate</th>
                            </tr>
                          </thead>
                          <tbody>
                            {finish.types.map((type, typeIndex) => (
                              <tr
                                className="finishes-popup-types-row"
                                key={typeIndex}
                              >
                                <td className="finishes-popup-type-name finishes-popup-type-width">
                                  <div
                                    className="finish-type-title finish-popup-type-title"
                                    onMouseEnter={() =>
                                      setHoveredType(
                                        `${tableIndex}-${finishIndex}-${typeIndex}`
                                      )
                                    }
                                    onMouseLeave={() => setHoveredType(null)}
                                    onDoubleClick={() =>
                                      setUserEditType(
                                        `${tableIndex}-${finishIndex}-${typeIndex}`
                                      )
                                    }
                                  >
                                    <span>{type.name}</span>
                                    <span className="finish-type-edit-icons">
                                      <FaEdit
                                        className="finish-type-edit-icon"
                                        onClick={() => {
                                          setAddPopup("edit_type");
                                          setUserCategoryData({
                                            category: table.name,
                                            finish: finish.name,
                                            type: type.name,
                                            category_id: table._id,
                                            finish_id: finish._id,
                                            type_id: type._id,
                                            unit: type.unit,
                                          });
                                          formRef.current.scrollIntoView({
                                            behavior: "smooth",
                                          });
                                        }}
                                      />
                                      <MdDelete
                                        className="finish-type-edit-icon"
                                        onClick={() =>
                                          setDeleteCategories((prev) => ({
                                            ...prev,
                                            category: table.name,
                                            category_id: table._id,
                                            finish: finish.name,
                                            finish_id: finish._id,
                                            type_id: type._id,
                                            type: type.name,
                                          }))
                                        }
                                      />
                                    </span>
                                  </div>

                                  {hoveredType ===
                                    `${tableIndex}-${finishIndex}-${typeIndex}` &&
                                    type.description &&
                                    !userEditType && (
                                      <p className="finish-type-title-description">
                                        {type.description}
                                      </p>
                                    )}
                                  {userEditType ===
                                    `${tableIndex}-${finishIndex}-${typeIndex}` &&
                                    userEditType && (
                                      <textarea
                                        ref={usereditDescrptionRef}
                                        placeholder="Enter Your description"
                                        value={type.description}
                                        onChange={(e) =>
                                          handleUserCategoryChange(
                                            table._id,
                                            finish._id,
                                            type._id,
                                            "description",
                                            e.target.value
                                          )
                                        }
                                        className="finish-type-title-edit-description"
                                        spellCheck="false"
                                      />
                                    )}
                                </td>

                                <td className="finishes-popup-type-name">
                                  {type.unit !== "W" && (
                                    <p className="finish-type-title finish-popup-type-title">
                                      {type.unit === "HW" ? "SFT" : type.unit}
                                    </p>
                                  )}
                                  {type.unit === "W" && (
                                    <select
                                      onChange={(e) =>
                                        handleUserCategoryChange(
                                          table._id,
                                          finish._id,
                                          type._id,
                                          "widthUnit",
                                          e.target.value
                                        )
                                      }
                                      value={type.widthUnit}
                                      className="finish-type-title finish-popup-type-title"
                                      style={{
                                        border: "none",
                                        outline: "none",
                                        padding: 0,
                                      }}
                                    >
                                      <option value="RFT" selected>
                                        RFT
                                      </option>
                                      <option value="SFT">SFT</option>
                                      <option value="QTY">QTY</option>
                                    </select>
                                  )}
                                </td>

                                <td className="finishes-popup-rate-input">
                                  <span>{type.rate ? "₹" : ""}</span>
                                  <input
                                    type="text"
                                    value={type.rate}
                                    onChange={(e) =>
                                      handleUserCategoryChange(
                                        table._id,
                                        finish._id,
                                        type._id,
                                        "rate",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}
        </div>

        {currentAccountType === "organization" && (
          <form
            class="finishes-popup-add-button"
            onSubmit={handleSubmittedCategory}
            ref={formRef}
          >
            {addPopup && (
              <div className="finishes-add-popup show">
                <IoMdClose
                  className="finishes-popup-container-icon"
                  onClick={() => {
                    setAddPopup(false);
                    setUserCategoryData({
                      category: "",
                      finish: "",
                      type: "",
                      unit: "",
                    });
                    setSuggestions((prev) => ({
                      ...prev,
                      categories: [],
                      finishes: [],
                      types: [],
                    }));
                  }}
                />
                <div className="finishes-add-popup-field finishes-add-popup-category">
                  <label
                    htmlFor="new-category"
                    className="finishes-add-popup-label"
                  >
                    Category
                  </label>

                  <input
                    type="text"
                    id="new-category"
                    className="finishes-add-popup-input"
                    placeholder="Enter your category"
                    name="category"
                    value={userCategoryData.category}
                    onChange={handleCategoryData}
                    required
                    autoComplete="off"
                    disabled={
                      addPopup === "edit_finish" || addPopup === "edit_type"
                    }
                    spellCheck="false"
                  />
                  {addPopup !== "edit_finish" &&
                    addPopup !== "edit_type" &&
                    userCategoryData &&
                    userCategoryData.category && (
                      <p className="error characters-limit-error">
                        {Math.max(
                          25 - (userCategoryData?.category?.length || 0),
                          0
                        )}{" "}
                        characters remaining
                      </p>
                    )}
                  {userCategoryData.category &&
                    suggestions.categories.length > 0 && (
                      <ul
                        className="dropdown-list parter-dropdown-list"
                        style={{ marginTop: "0" }}
                      >
                        {suggestions.categories.map(
                          (category, categoryIndex) => (
                            <li
                              key={categoryIndex}
                              className="dropdown-item"
                              onClick={() => {
                                setUserCategoryData((prev) => ({
                                  ...prev,
                                  category,
                                }));
                                setSuggestions((prev) => ({
                                  ...prev,
                                  categories: [],
                                }));
                              }}
                            >
                              {category}
                            </li>
                          )
                        )}
                      </ul>
                    )}
                </div>

                <div className="finishes-add-popup-field finishes-add-popup-finish">
                  <label
                    htmlFor="new-finishe"
                    className="finishes-add-popup-label"
                  >
                    Finish
                  </label>
                  <input
                    type="text"
                    id="new-finish"
                    className="finishes-add-popup-input"
                    placeholder="Enter your finish"
                    name="finish"
                    value={userCategoryData.finish}
                    onChange={handleCategoryData}
                    required
                    autoComplete="off"
                    disabled={
                      addPopup === "edit_category" || addPopup === "edit_type"
                    }
                    spellCheck="false"
                  />
                  {addPopup !== "edit_category" &&
                    addPopup !== "edit_type" &&
                    userCategoryData.finish && (
                      <p className="error characters-limit-error">
                        {Math.max(
                          25 - (userCategoryData?.finish?.length || 0),
                          0
                        )}{" "}
                        characters remaining
                      </p>
                    )}
                  {userCategoryData.finish &&
                    suggestions.finishes.length > 0 && (
                      <ul
                        className="dropdown-list parter-dropdown-list"
                        style={{ marginTop: "0" }}
                      >
                        {suggestions.finishes.map((finish, finishIndex) => (
                          <li
                            key={finishIndex}
                            className="dropdown-item"
                            onClick={() => {
                              setUserCategoryData((prev) => ({
                                ...prev,
                                finish,
                              }));
                              setSuggestions((prev) => ({
                                ...prev,
                                finishes: [],
                              }));
                            }}
                          >
                            {finish}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>

                <div className="finishes-add-popup-field finishes-add-popup-type">
                  <label
                    htmlFor="new-type"
                    className="finishes-add-popup-label"
                  >
                    Type
                  </label>
                  <input
                    type="text"
                    id="new-type"
                    className="finishes-add-popup-input"
                    placeholder="Enter your type"
                    name="type"
                    value={userCategoryData.type}
                    onChange={handleCategoryData}
                    required
                    autoComplete="off"
                    disabled={
                      addPopup === "edit_category" || addPopup === "edit_finish"
                    }
                    spellCheck="false"
                  />
                  {addPopup !== "edit_category" &&
                    addPopup !== "edit_finish" &&
                    userCategoryData.type && (
                      <p className="error characters-limit-error">
                        {Math.max(
                          25 - (userCategoryData?.type?.length || 0),
                          0
                        )}{" "}
                        characters remaining
                      </p>
                    )}
                </div>

                <div className="finishes-add-popup-field finishes-add-popup-unit">
                  <label
                    htmlFor="new-unit"
                    className="finishes-add-popup-label"
                  >
                    Input Method
                  </label>
                  <select
                    id="new-unit"
                    className="finishes-add-popup-select"
                    name="unit"
                    value={userCategoryData.unit}
                    onChange={handleCategoryData}
                    required
                    disabled={
                      addPopup === "edit_category" || addPopup === "edit_finish"
                    }
                    spellCheck="false"
                  >
                    <option value="" disabled selected>
                      Select Input Method
                    </option>
                    <option value="HW">HW</option>
                    <option value="W">W</option>
                    <option value="LSUM">LSUM</option>
                  </select>
                </div>
              </div>
            )}

            <button
              onClick={() => (!addPopup ? setAddPopup("new") : "")}
              type={!addPopup ? "button" : "submit"}
            >
              {addPopup ? "Submit" : "Add Yours"}
            </button>
          </form>
        )}
      </div>

      {!isSaved && (
        <button className="rate-save-button" onClick={handleSubmitRate}>
          Save
        </button>
      )}
      {isSaved && (
        <button className="rate-save-button rate-saved-button">
          Saved Successfully
        </button>
      )}
    </section>
  );
}

export default FinishesTypesPopup;
