import { v4 as uuidv4 } from "uuid";
import AXIOS from "./axios";

// const removeTable = (id) => {
//   const tableIndex = areaTables.findIndex((table) => table.id === id);

//   if (tableIndex === -1) return; // Table not found

//   const previousTableId =
//     tableIndex > 0 ? areaTables[tableIndex - 1].id : null;
//   const remainingHeight = canAddTable(previousTableId);

//   let updatedAreaTables;

//   if (remainingHeight > 300) {
//     // Remove the table completely
//     updatedAreaTables = areaTables.filter((table) => table.id !== id);
//   } else {
//     // Empty the area
//     updatedAreaTables = areaTables.map((table) => {
//       if (table.id === id) {
//         return {
//           ...table,
//           area: [],
//         };
//       }
//       return table;
//     });
//   }

//   // Update the state
//   setAreaTables(updatedAreaTables);
//   setRateTables(updatedAreaTables);

//   return previousTableId;
// };

//function to timer to show resend button

export function resendOtpTimer(updateState, startTime) {
  let timer = startTime;

  const intervalId = setInterval(() => {
    if (timer > 0) {
      timer -= 1;
      updateState(timer);
    } else {
      clearInterval(intervalId);
    }
  }, 1000);

  return () => clearInterval(intervalId);
}

//to set same initial state uuid value in quotation Table
export const generateTable = () => {
  return {
    id: uuidv4(),
    area: [
      {
        id: uuidv4(),
        areaValue: "",
        amount: "",
        items: [
          {
            id: uuidv4(),
            itemValue: "",
            descriptionValue: "",
            amount: "",
            subItems: [
              {
                id: uuidv4(),
                subItemValue: "",
                categoryType: "",
                category: "",
                finish: "",
                type: "",
                categoryId: "",
                finishId: "",
                typeId: "",
                description: "",
                height: "",
                width: "",
                amount: "",
                unit: "",
                range: "",
                rate: "",
                interioverseRate: false,
                updatedRate: "",
                warning: "",
                widthUnit: "",
              },
            ],
          },
        ],
      },
    ],
  };
};

export function checkForWarnings(data) {
  for (const table of data) {
    for (const area of table.area) {
      for (const item of area.items) {
        for (const subItem of item.subItems) {
          if (subItem.warning) {
            return true; // Found a subItem with a warning, return true
          }
        }
      }
    }
  }
  return false; // No warnings found
}

export const intialRateTable = (table) => {
  return {
    id: table.id,
    area: table.area.map((area) => ({
      id: area.id,
      items: area.items.map((item) => ({
        id: item.id,
        subItems: item.subItems.map((subItem) => ({
          id: subItem.id,
          rateValue: "",
        })),
      })),
    })),
  };
};

export const handleMoveItemToNext = (
  tableIndex,
  tableId,
  areaTableId,
  areaTables,
  rateTables,
  setAreaTables,
  setRateTables
) => {
  const newItemId = uuidv4();
  const newSubItemId = uuidv4();

  const addItem = (tables) =>
    tables.map((table) => {
      if (table.id === tableId) {
        return {
          ...table,
          area: table.area.map((area) => {
            if (area.id === areaTableId) {
              return {
                ...area,
                items: [
                  ...area.items,
                  {
                    id: newItemId,
                    subItems: [{ id: newSubItemId }],
                  },
                ],
              };
            }
            return area;
          }),
        };
      }
      return table;
    });

  setAreaTables(addItem(areaTables));
  setRateTables(addItem(rateTables));

  // Proceed with moving the last item of the last area if needed
  let lastItem;
  let lastAreaValue;
  let lastAreaDeleted = false;

  const updatedTables = areaTables.map((table, index) => {
    if (index === tableIndex) {
      const lastArea = table.area[table.area.length - 1];
      lastItem = lastArea.items.pop();
      lastAreaValue = lastArea.areaValue;

      if (lastArea.items.length === 0) {
        // If the last area has no items left after removing the last item, delete the last area
        lastAreaDeleted = true;
        return {
          ...table,
          area: table.area.slice(0, -1), // Remove the last area
        };
      }

      return {
        ...table,
        area: table.area.map((area, areaIndex) => {
          if (areaIndex === table.area.length - 1) {
            return {
              ...area,
              items: lastArea.items,
            };
          }
          return area;
        }),
      };
    }
    return table;
  });

  const nextTable = areaTables[tableIndex + 1];
  const firstAreaInNextTable = nextTable.area[0];

  if (lastAreaValue === firstAreaInNextTable.areaValue) {
    // Add last item of the last area in the current table to the last position in the first area of the next table
    const nextTableUpdated = {
      ...nextTable,
      area: nextTable.area.map((area, areaIndex) => {
        if (areaIndex === 0) {
          return {
            ...area,
            items: [...area.items, lastItem],
          };
        }
        return area;
      }),
    };

    const finalUpdatedTables = updatedTables.map((table, index) => {
      if (index === tableIndex + 1) {
        return nextTableUpdated;
      }
      return table;
    });

    setAreaTables(finalUpdatedTables);
    setRateTables(finalUpdatedTables);
  } else {
    // Create a new area in the next table and add the removed item to it
    const newAreaID = uuidv4();

    const nextTableUpdated = {
      ...nextTable,
      area: [
        {
          id: newAreaID,
          areaValue: lastAreaValue,
          items: [lastItem],
        },
        ...nextTable.area,
      ],
    };

    const finalUpdatedTables = updatedTables.map((table, index) => {
      if (index === tableIndex + 1) {
        return nextTableUpdated;
      }
      return table;
    });

    setAreaTables(finalUpdatedTables);
    setRateTables(finalUpdatedTables);
  }
};

export const updateAmount = (
  setAreaTables,
  setRateTables,
  tableId,
  areaId,
  itemId,
  subItemId,
  subIndex
) => {
  setAreaTables((currentTables) =>
    currentTables.map((table) => {
      if (table.id !== tableId) return table;

      return {
        ...table,
        area: table.area.map((area) => {
          if (area.id !== areaId) return area;
          return {
            ...area,
            items: area.items.map((item) => {
              if (item.id !== itemId) return item;
              return {
                ...item,
                subItems: item.subItems.map((subItem, index) => {
                  if (subItem.id === subItemId && index === subIndex) {
                    let rate = parseFloat(subItem.rate) || 0;
                    let height = parseFloat(subItem.height) || 0;
                    let width = parseFloat(subItem.width) || 0;
                    let range = parseFloat(subItem.range) || 0;
                    if (!height || height < 1) {
                      height = 1;
                    }

                    if (!width || width < 1) {
                      width = 1;
                    }

                    let newAmount;
                    if (!range || range < 1) {
                      newAmount = Math.ceil(height * width * rate);
                    } else {
                      newAmount =
                        Math.ceil((height * width) / parseFloat(range)) * rate;
                    }

                    return {
                      ...subItem,
                      amount: newAmount,
                    };
                  }
                  return subItem;
                }),
              };
            }),
          };
        }),
      };
    })
  );
};

export const minusAmount = (
  setAreaTables,
  tableId,
  areaId,
  itemId,
  subItemId,
  subIndex
) => {
  setAreaTables((currentTables) =>
    currentTables.map((table) => {
      if (table.id !== tableId) return table;

      return {
        ...table,
        area: table.area.map((area) => {
          if (area.id !== areaId) return area;
          return {
            ...area,
            items: area.items.map((item) => {
              if (item.id !== itemId) return item;
              return {
                ...item,
                subItems: item.subItems.map((subItem, index) => {
                  if (subItem.id === subItemId && index === subIndex) {
                    return {
                      ...subItem,
                      amount: 0,
                    };
                  }
                  return subItem;
                }),
              };
            }),
          };
        }),
      };
    })
  );
};

export const updateItemAmount = (setAreaTables, tableId, areaId, itemId) => {
  // Update setAreaTables with updated item amount
  setAreaTables((currentTables) =>
    currentTables.map((table) => {
      if (table.id !== tableId) return table;
      return {
        ...table,
        area: table.area.map((area) => {
          if (area.id !== areaId) return area;

          return {
            ...area,
            items: area.items.map((item) => {
              if (item.id !== itemId) return item;

              // Calculate total amount for the item by summing up the amounts of all its sub-items
              const totalAmount = item.subItems.reduce(
                (acc, subItem) => acc + parseFloat(subItem.amount || 0),
                0
              );

              // Return the updated item with the total amount
              return {
                ...item,
                amount: totalAmount,
              };
            }),
          };
        }),
      };
    })
  );
};

export const minusItemAmount = (setAreaTables, tableId, areaId, itemId) => {
  // Update setAreaTables with updated item amount
  setAreaTables((currentTables) =>
    currentTables.map((table) => {
      if (table.id !== tableId) return table;

      return {
        ...table,
        area: table.area.map((area) => {
          if (area.id !== areaId) return area;

          return {
            ...area,
            items: area.items.map((item) => {
              if (item.id !== itemId) return item;

              // Return the updated item with the total amount
              return {
                ...item,
                amount: 0,
              };
            }),
          };
        }),
      };
    })
  );
};

export const updateAreaAmount = (setAreaTables, tableId, areaId) => {
  // Update setAreaTables with updated area amount
  setAreaTables((currentTables) =>
    currentTables.map((table) => {
      if (table.id !== tableId) return table;

      return {
        ...table,
        area: table.area.map((area) => {
          if (area.id !== areaId) return area;

          // Calculate total amount for the area by summing up the amounts of all its items
          const totalAmount = area.items.reduce(
            (acc, item) => acc + parseFloat(item.amount || 0),
            0
          );

          // Return the updated area with the total amount
          return {
            ...area,
            amount: totalAmount,
          };
        }),
      };
    })
  );
};

export const minusAreaAmount = (setAreaTables, tableId, areaId) => {
  setAreaTables((currentTables) =>
    currentTables.map((table) => {
      if (table.id !== tableId) return table;

      const updatedArea = table.area.map((area) => {
        if (area.id !== areaId) return area;

        // Return the updated area with the total amount set to 0
        return {
          ...area,
          amount: 0,
        };
      });

      return {
        ...table,
        area: updatedArea,
      };
    })
  );
};

export const updateTableAmount = ({
  setAreaTables,
  tableId,
  areaId,
  itemId = null,
  subItemId = null,
  subIndex = null,
}) => {
  setAreaTables((currentTables) =>
    currentTables.map((table) => {
      if (table.id !== tableId) return table;

      return {
        ...table,
        area: table.area.map((area) => {
          if (area.id !== areaId) return area;

          if (!itemId) {
            // Update area amount
            const totalAmount = area.items.reduce(
              (acc, item) => acc + parseFloat(item.amount || 0),
              0
            );

            return {
              ...area,
              amount: totalAmount,
            };
          }

          return {
            ...area,
            items: area.items.map((item) => {
              if (item.id !== itemId) return item;

              if (!subItemId) {
                // Update item amount
                const totalAmount = item.subItems.reduce(
                  (acc, subItem) => acc + parseFloat(subItem.amount || 0),
                  0
                );

                return {
                  ...item,
                  amount: totalAmount,
                };
              }

              return {
                ...item,
                subItems: item.subItems.map((subItem, index) => {
                  if (subItem.id === subItemId && index === subIndex) {
                    // Update subItem amount
                    let rate = parseFloat(subItem.rate) || 0;
                    let height = parseFloat(subItem.height) || 0;
                    let width = parseFloat(subItem.width) || 0;
                    let range = parseFloat(subItem.range) || 0;

                    // Ensure height and width have valid values
                    height = height < 1 ? 1 : height;
                    width = width < 1 ? 1 : width;

                    let newAmount;
                    if (!range || range < 1) {
                      newAmount = Math.ceil(height * width * rate);
                    } else {
                      newAmount =
                        Math.ceil((height * width) / range) * rate;
                    }

                    return {
                      ...subItem,
                      amount: newAmount,
                    };
                  }
                  return subItem;
                }),
              };
            }),
          };
        }),
      };
    })
  );
};




export const updateSubTotalAmount = (
  setAreaTables,
  setSubTotalTables,
  subTotalTables
) => {
  if (!subTotalTables) {
    console.error("subTotalTables is undefined");
    return;
  }

  // Reset gst, add, and minus values to their initial state
  const resetSubTotalTables = {
    ...subTotalTables,
  };

  setAreaTables((currentTables) => {
    // Calculate the total amount by summing up the amounts of all areas in all tables
    const totalAmount = currentTables.reduce((total, table) => {
      const tableTotal = table.area.reduce(
        (tableAcc, area) => tableAcc + parseFloat(area.amount || 0),
        0
      );
      return total + tableTotal;
    }, 0);

    let total = totalAmount;
    let tableTotal = totalAmount; // Initialize tableTotal with totalAmount

    // Update the subtotal in subTotalTables
    setSubTotalTables((prevSubTotalTables) => {
      const newAddArray = resetSubTotalTables.add
        ? resetSubTotalTables.add.map((item) => {
            const percentage = parseFloat(item.percentage);
            if (isNaN(percentage)) return { ...item, amount: "0" };
            const addAmount = Math.ceil((percentage * totalAmount) / 100);
            total += addAmount;
            return { ...item, amount: addAmount.toFixed(0) };
          })
        : [];

      const newMinusArray = resetSubTotalTables.minus
        ? resetSubTotalTables.minus.map((item) => {
            const percentage = parseFloat(item.percentage);
            if (isNaN(percentage)) return { ...item, amount: "0" };
            const minusAmount = Math.ceil((percentage * totalAmount) / 100);
            total -= minusAmount;
            return { ...item, amount: minusAmount.toFixed(0) };
          })
        : [];

      let newGstAmount = 0;
      if (resetSubTotalTables.gst && resetSubTotalTables.gst.percentage) {
        const gstPercentage = parseFloat(resetSubTotalTables.gst.percentage);
        if (!isNaN(gstPercentage)) {
          newGstAmount = Math.ceil((gstPercentage * total) / 100);
        }
      }

      // Round total to two decimal places and then ceil it
      total = Math.ceil(total); // No need to use toFixed() before Math.ceil()
      tableTotal = Math.ceil(total + newGstAmount); // No need to use toFixed() before Math.ceil()

      return {
        ...resetSubTotalTables,
        add: newAddArray,
        minus: newMinusArray,
        gst: { ...resetSubTotalTables.gst, amount: newGstAmount.toFixed(0) },
        subTotal: `${totalAmount}`,
        total: tableTotal.toFixed(0),
        taxableAmount: total.toFixed(0),
      };
    });

    // Return the updated tables without modifying the actual table structure
    return currentTables.map((table) => ({
      ...table,
      subTotal: totalAmount,
      taxableAmount: total.toFixed(0),
      total: tableTotal.toFixed(0),
    }));
  });
};

export const fetchUserRates = async (
  setRates,
  setUserCategories,
  accountType
) => {
  try {
    const response = await AXIOS.get(
      `${
        accountType === "organization"
          ? "organization/get-organization-rate"
          : "/quotation/get-user-rate"
      }`
    );
    if (response.status === 200) {
      const filteredRates = response.data.rates;

      const transformedRates = filteredRates.map((rate) => ({
        category: rate.category,
        finish: rate.finish,
        type: rate.type,
        rate: rate.rate ? rate.rate : "",
        description: rate.description ? rate.description : "",
        widthUnit: rate.widthUnit ? rate.widthUnit : "",
      }));

      setRates(transformedRates);
      setUserCategories(response.data.organizationCategories);
    }
  } catch (error) {
    console.error("Error fetching quotations:", error);
  }
};

export const calculateTotal = (setSubTotalTables, subTotalTables) => {
  let subTotal = parseFloat(subTotalTables.subTotal.replace(/[^0-9.-]+/g, ""));
  let total = subTotal;

  subTotalTables.add.forEach((item) => {
    if (item.amount) {
      total += parseFloat(item.amount);
    }
  });

  subTotalTables.minus.forEach((item) => {
    if (item.amount) {
      total -= parseFloat(item.amount);
    }
  });

  // Calculate GST amount based on the updated total
  const gstPercentage = parseFloat(subTotalTables.gst.percentage) || 0;
  const gstAmount = (total * gstPercentage) / 100;

  // Round total to two decimal places and then ceil it
  total = Math.ceil(total.toFixed(0));

  // Update state
  setSubTotalTables((prevState) => ({
    ...prevState,
    taxableAmount: total.toFixed(0),
    gst: {
      ...prevState.gst,
      amount: gstAmount.toFixed(0),
    },
    total: (total + gstAmount).toFixed(0),
  }));
};

export const handleLogoClick = async (
  tableId,
  areaId,
  itemId,
  subItemId,
  subIndex,
  projectId,
  category,
  finish,
  type,
  areaTables,
  setAreaTables,
  setRateTables,
  setSubTotalTables,
  subTotalTables,
  rates,
  currentAccountType
) => {
  const updateRates = (rateValue, rateType) => {
    setAreaTables((currentTables) =>
      currentTables.map((table) =>
        table.id === tableId
          ? {
              ...table,
              area: table.area.map((area) =>
                area.id === areaId
                  ? {
                      ...area,
                      items: area.items.map((item) =>
                        item.id === itemId
                          ? {
                              ...item,
                              subItems: item.subItems.map((subItem) =>
                                subItem.id === subItemId
                                  ? {
                                      ...subItem,
                                      rate: rateValue ? rateValue : "",
                                      interioverseRate:
                                        rateType === "invt" ? true : false,
                                    }
                                  : subItem
                              ),
                            }
                          : item
                      ),
                    }
                  : area
              ),
            }
          : table
      )
    );

    // Call the necessary functions to update the amounts and totals
    updateAmount(
      setAreaTables,
      setRateTables,
      tableId,
      areaId,
      itemId,
      subItemId,
      subIndex
    );
    updateItemAmount(setAreaTables, tableId, areaId, itemId);
    updateAreaAmount(setAreaTables, tableId, areaId);
    calculateTotal(setSubTotalTables, subTotalTables);
    updateSubTotalAmount(setAreaTables, setSubTotalTables, subTotalTables);
  };

  const isPresent = areaTables.some((table) =>
    table.area.some((area) =>
      area.items.some((item) =>
        item.subItems.some(
          (subItem) => subItem.id === subItemId && subItem.interioverseRate
        )
      )
    )
  );

  if (isPresent) {
    const getRate = (category, finish, type) => {
      const rateObj = rates.find(
        (rate) =>
          rate.category.toLowerCase() === category.toLowerCase() &&
          rate.finish.toLowerCase() === finish.toLowerCase() &&
          rate.type.toLowerCase() === type.toLowerCase()
      );
      return rateObj ? rateObj.rate : "";
    };

    let rateValue = getRate(category, finish, type);
    updateRates(rateValue, "user");
  } else {
    AXIOS.post(`/${
          currentAccountType === "organization" 
            ? "organization":"admin"}/rate-details`, {
      projectId: projectId,
      categoryName: category,
      finishName: finish,
      typeName: type,
    })
      .then((response) => {
        if (response.status === 200) {
          let rateValue = response.data.rate;
          updateRates(rateValue, "invt");
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error("Error data:", error.response.data);
          console.error("Error status:", error.response.status);
          console.error("Error headers:", error.response.headers);
        } else if (error.request) {
          console.error("Error request:", error.request);
        } else {
          console.error("Error message:", error.message);
        }
        console.error("Error config:", error.config);
      });
  }
};

export const formatIndianNumber = (num) => {
  if (num === undefined || num === null) return "";

  const [integerPart, decimalPart] = num.toString().split(".");
  const lastThreeDigits = integerPart.slice(-3);
  const otherDigits = integerPart.slice(0, -3);

  const formattedNumber =
    otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
    (otherDigits ? "," : "") +
    lastThreeDigits;
  return decimalPart ? formattedNumber + "." + decimalPart : formattedNumber;
};

export function extractCategoryInfo(data) {
  const categoryInfo = [];
  data.forEach((table) => {
    const tableId = table.id;
    table.area.forEach((areaItem) => {
      const areaId = areaItem.id; // Correct variable naming
      areaItem.items.forEach((item) => {
        const itemId = item.id; // Correct variable naming
        item.subItems.forEach((subItem) => {
          const {
            categoryId,
            finishId,
            typeId,
            category,
            finish,
            type,
            height,
            width,
            unit,
            range,
            amount,
          } = subItem;

          const entry = {
            categoryId,
            finishId,
            typeId,
            category,
            finish,
            type,
            height,
            width,
            unit,
            range,
            areaId,
            itemId,
            tableId,
          };

          if (category === "Other") {
            entry.amount = amount;
          }

          categoryInfo.push(entry);
        });
      });
    });
  });
  return categoryInfo;
}

export const lightenColor = (color, amount) => {
  // Convert hex color to RGB
  const hexToRgb = (hex) => {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
      return r + r + g + g + b + b;
    });
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  };

  // Convert RGB to hex color
  const rgbToHex = (r, g, b) => {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  };

  // Extract RGB values from color
  let { r, g, b } = hexToRgb(color);

  // Calculate lighter color
  r += amount;
  g += amount;
  b += amount;

  // Limit values to 255
  r = Math.min(r, 255);
  g = Math.min(g, 255);
  b = Math.min(b, 255);

  // Return lighter color in hex format
  return rgbToHex(r, g, b);
};

export const handleBrowserBackButton = (navigate, path) => {
  window.onpopstate = function (event) {
    navigate(path);
  };
};

export const checkSubTotalTablesPercentage = (subTotalTables) => {
  // Check if any 'add' array item has a percentage value
  const hasAddPercentage = subTotalTables.add.some(
    (item) => item.percentage !== ""
  );
  if (hasAddPercentage) {
    return true;
  }

  // Check if any 'minus' array item has a percentage value
  const hasMinusPercentage = subTotalTables.minus.some(
    (item) => item.percentage !== ""
  );
  if (hasMinusPercentage) {
    return true;
  }

  // If no percentage found, return false
  return false;
};

export const getValueForColumn = (column, subItemTable) => {
  switch (column) {
    case "Height":
      return subItemTable.height !== "Other" ? subItemTable.height : "-";
    case "Width":
      return subItemTable.width !== "Other" ? subItemTable.width : "-";
    case "Area":
      const area =
        (Number(subItemTable.height) || 1) * (Number(subItemTable.width) || 1);
      const roundedArea = Math.ceil(area * 100) / 100;
      return roundedArea % 1 === 0 ? roundedArea : roundedArea.toFixed(1);
    case "Unit":
      return subItemTable.widthUnit;
    case "Rate":
      return subItemTable.rate;
    default:
      return "";
  }
};

export const getUnappliedColumns = (appliedColumns) => {
  const allColumns = ["Height", "Width", "Area", "Unit", "Rate"];

  // Assuming appliedColumns is a Set of applied column names
  return allColumns.filter((column) => !appliedColumns.has(column));
};

export const getPositionAndDimensions = (activePage) => {
  let newPosition = { x: 0, y: 0 };
  let width = 0;
  let height = 0;

  if (activePage?.quillRef?.current) {
    const quill = activePage.quillRef.current;
    const range = quill.getSelection();

    if (range) {
      const bounds = quill.getBounds(range.index);
      newPosition = { x: bounds.left + 30, y: bounds.top + 20 };
    }
  }

  width = activePage?.editorRef?.current?.offsetWidth || 0;
  height = activePage?.editorRef?.current?.offsetHeight || 0;

  // Ensure the box stays within boundaries
  newPosition.x = Math.min(newPosition.x, width - 130);
  newPosition.y = Math.min(newPosition.y, height - 45);

  return { x: newPosition.x, y: newPosition.y };
};

export const getProjectBoxPositionAndDimensions = (activePage) => {
  let newPosition = { x: 0, y: 0 };
  let width = 0;
  let height = 0;

  if (activePage?.quillRef?.current) {
    const quill = activePage.quillRef.current;
    const range = quill.getSelection();

    if (range) {
      const bounds = quill.getBounds(range.index);
      newPosition = { x: bounds.left + 30, y: bounds.top + 10 };
    }
  }

  width = activePage?.editorRef?.current?.offsetWidth || 0;
  height = activePage?.editorRef?.current?.offsetHeight || 0;

  // Ensure the box stays within boundaries
  newPosition.x = Math.min(newPosition.x, width - 135);
  newPosition.y = Math.min(newPosition.y, height);

  return { x: newPosition.x, y: newPosition.y };
};

export const generateAndSetSummary = (areaTables, subTotalTables, setSummary) => {
    const newSummary = {
      area: areaTables.flatMap((table) =>
        (table.area || []).map((area) => ({
          name: area.areaValue || "",
          value: area.amount || "",
        }))
      ),
      subtotal: subTotalTables.subTotal || "0",
      total: subTotalTables.total || "0",
    };
  
    setSummary(newSummary);
  };
