import React, { useEffect, useRef, useState } from "react";
import "./LandingPageFinishesTypes.css";
import AXIOS from "../../services/axios";

function LandingPageFinishesTypes({
  setActiveDropdown,
  setGetRates,
  getRates,
}) {
  const [finishes, setFinishes] = useState([]);
  const [expandedFinish, setExpandedFinish] = useState(null);
  const [hoveredType, setHoveredType] = useState(null);
  const wrapperRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    // Fetch data when component mounts
    const fetchQuotations = async () => {
      try {
        const response = await AXIOS.get(`/admin/all-quotations`);
        if (response.status === 200) {
          setFinishes(response.data);
        }
      } catch (error) {
        console.error("Error fetching quotations:", error);
      }
    };

    fetchQuotations();
  }, []);

  const toggleFinish = (index) => {
    setExpandedFinish(expandedFinish === index ? null : index);
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setExpandedFinish(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const selectFinishType = (categoryId, finishId, typeId,category, finish, type, description) => {
    if (getRates.location === "Bengaluru/Bangalore") {
      getRates.location = "Bengaluru";
    }

    handleGetRate(getRates.location, categoryId, finishId, typeId,);

    setGetRates((prev) => ({
      ...prev,
      finish: finish,
      type: type,
      description: description,
    }));

    // Reset active dropdown and expanded finish
    setActiveDropdown(false);
  };

  const handleFinishTypeClick = (categoryId, finishId, typeId,category, finish, type, description) => {
    selectFinishType(categoryId, finishId, typeId,category, finish, type, description);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  // Function to filter finishes based on the search query
  const filterFinishes = (categories) => {
    if (!searchTerm) return categories;

    return categories.filter((category) =>
      category.finishes.some((finish) =>
        finish.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  };

  const handleGetRate = async (location, category, finish, type) => {
    AXIOS.post("/admin/get-rate", {
      location: location,
      categoryId: category,
      finishId: finish,
      typeId: type,
    })
      .then((response) => {
        if (response.status === 200) {
          let rateValue = response.data.rate;

          setGetRates((prev) => ({
            ...prev,
            rate: Math.ceil(rateValue * 1.1),
            location: location,
          }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <section className="landing-page-finishes-section">
      <input
        type="text"
        placeholder="Search Finishes and Types"
        className="landing-page-finishes-search-bar"
        onChange={handleSearchChange}
      />

      <div className="landing-page-finishes-list">
        {finishes &&
          finishes.length > 0 &&
          finishes[0].categories &&
          finishes[0].categories.length > 0 &&
          (filterFinishes(finishes[0].categories).length > 0 ? (
            filterFinishes(finishes[0].categories).map(
              (category, categoryIndex) => (
                <div key={categoryIndex} className="landing-page-finishes-item">
                  <h2 className="landing-page-container-title">
                    {category.name}
                  </h2>
                  {category.finishes &&
                    category.finishes.length > 0 &&
                    category.finishes.map((finish, finishIndex) => (
                      <div key={finishIndex}>
                        <h3
                          className="landing-page-finishes-title"
                          onClick={() =>
                            toggleFinish(`${categoryIndex}-${finishIndex}`)
                          }
                        >
                          {finish.name}
                        </h3>
                        {expandedFinish ===
                          `${categoryIndex}-${finishIndex}` && (
                          <ul
                            className="landing-page-finish-types"
                            ref={wrapperRef}
                          >
                            {finish.types &&
                              finish.types.length > 0 &&
                              finish.types.map((type, typeIndex) => (
                                <li
                                  key={typeIndex}
                                  className="landing-page-finish-type-item"
                                >
                                  <h4
                                    className="landing-page-finish-type-title"
                                    onClick={() =>
                                      handleFinishTypeClick(
                                        category._id,
                                        finish._id,
                                        type._id,
                                        category.name,
                                        finish.name,
                                        type.name,
                                        type.description
                                      )
                                    }
                                    onMouseEnter={() =>
                                      setHoveredType(
                                        `${categoryIndex}-${finishIndex}-${typeIndex}`
                                      )
                                    }
                                    onMouseLeave={() => setHoveredType(null)}
                                  >
                                    {type.name}
                                  </h4>
                                  {hoveredType ===
                                    `${categoryIndex}-${finishIndex}-${typeIndex}` &&
                                    type.description && (
                                      <p className="landing-page-finish-type-title-description">
                                        {type.description}
                                      </p>
                                    )}
                                </li>
                              ))}
                          </ul>
                        )}
                      </div>
                    ))}
                </div>
              )
            )
          ) : (
            <p>No finishes available.</p>
          ))}
      </div>
    </section>
  );
}

export default LandingPageFinishesTypes;
