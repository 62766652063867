import React, { useEffect, useState } from "react";
import "./pricing.css";
import { FaRegSquareCheck } from "react-icons/fa6";
import {
  IoIosCheckmarkCircle,
  IoMdInformationCircleOutline,
  IoMdClose,
} from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AXIOS from "../../services/axios";
import { handleBrowserBackButton } from "../../services/reusableCodes";

function Pricing() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const count = useSelector((state) => state.auth.count);
  const [referPopup, setReferPopup] = useState(false);
  const navigate = useNavigate();
  const [price, setPrice] = useState(null);

  useEffect(() => {
    const getPrice = async () => {
      try {
        const response = await AXIOS.get("/admin/get-price");
        if (response.status === 200) {
          setPrice(response.data.price);
          handleBrowserBackButton(navigate, "/");
        }
      } catch (error) {
        console.log(error);
      }
    };

    getPrice();
  }, []);

  return (
    <article
      className={`announcement ${
        isAuthenticated ? "signup-announcement" : ""
      } `}
    >
      <h1
        className={`announcement-title ${
          isAuthenticated ? "signup-announcement-title" : ""
        }`}
      >
        {isAuthenticated ? "Get the best fit for you" : "Its FREE"}
      </h1>

      {!isAuthenticated && (
        <section className="announcement-content">
          <p>To our cherished Interioverse community,</p>
          <p>
            We believe in the transformative power of spaces – how they can
            shape our emotions, nurture our dreams, and define our experiences.
            It's this belief that fuels our passion for providing our platform
            to you, our valued users, absolutely free.
          </p>
          <p>
            Creating a space isn't just about walls and furniture; it's about
            weaving together memories, comfort, and a sense of belonging. We
            want to break down barriers and empower everyone to design the space
            they deserve, regardless of their budget. You pay us only when you
            purchase an item or tender a project to us.
          </p>
          <p>
            <span className="announcement-gratitude">
              Your happiness and satisfaction drive us every day
            </span>
            . Seeing your faces light up when you step into a space you've
            designed with Interioverse – that's what makes it all worthwhile.
            So, thank you for being a part of our journey. Rest assured, we'll
            always be here, helping you turn your dreams into reality, one space
            at a time.
          </p>
          <p>With heartfelt gratitude,</p>
          <p>The Interioverse Team</p>
        </section>
      )}

      {!isAuthenticated && (
        <section className="announcement-info-section">
          <div className="announcement-info-block">
            <h2 className="announcement-info-title">Free for Lifetime</h2>
            <div className="announcement-info-subsection">
              <h6>FREE to Discover millions of design ideas</h6>
              <h6>FREE to Design your space the way you want</h6>
              <h6>FREE to Download the design in the way you want to use</h6>
            </div>
            <div className="announcement-info-benefits">
              <div className="announcement-info-benefit-list">
                <p>
                  <FaRegSquareCheck className="announcement-benefit-list-icon" />{" "}
                  Get instant - design-based quotation
                </p>
                <p>
                  <FaRegSquareCheck className="announcement-benefit-list-icon" />{" "}
                  Design at Leisure from Home
                </p>
                <p>
                  <FaRegSquareCheck className="announcement-benefit-list-icon" />{" "}
                  Real-life like Immersive designing in 3D
                </p>
              </div>
              <div className="announcement-info-benefit-list">
                <p>
                  <FaRegSquareCheck className="announcement-benefit-list-icon" />{" "}
                  Connect virtually with friends & families
                </p>
                <p>
                  <FaRegSquareCheck className="announcement-benefit-list-icon" />{" "}
                  Drag & Drop Design
                </p>
                <p>And Many More</p>
              </div>
            </div>
            <div className="announcement-info-summary">
              The ONE and ONLY platform in India where interior project
              planning, designing, and budgeting are done at leisure from home
              for FREE.
            </div>
          </div>
          <div className="announcement-designer-section">
            <h2 className="announcement-designer-title">Are You a Designer?</h2>
            <h3 className="announcement-designer-subtitle">
              The Platform is Still FREE
            </h3>
            <p className="announcement-designer-benefits">
              Unlock additional benefits by joining us as a Designer.
            </p>
            <p className="announcement-designer-description">
              Get a customizable quotation-making platform, provide instant
              design-based quotations to your clients, change designs with a
              click, and showcase your designs in a Real-time 3D Virtual World -
              The METAVERSE.
            </p>
            <div className="designer-signup-button">
              <button>Signup as a Designer</button>
            </div>

            <p className="announcement-endline">
              You can even buy a virtual showroom in METAVERSE!
            </p>
          </div>
        </section>
      )}

      {isAuthenticated && (
        <section
          className={`announcement-info-section ${
            isAuthenticated ? "signup-announcement-info-section" : ""
          }`}
        >
          <div
            className={`announcement-info-block ${
              isAuthenticated ? "signup-announcement-info-block" : ""
            }`}
          >
            <h2
              className={`announcement-info-title ${
                isAuthenticated ? "signup-announcement-info-title" : ""
              }`}
            >
              Its a License FREE platform
            </h2>
            <div
              className={`announcement-info-subsection ${
                isAuthenticated ? "signup-announcement-info-subsection" : ""
              }`}
            >
              <h6>FREE to Discover millions of design ideas</h6>
              <h6>FREE to Design your space the way you want</h6>
              <h6>FREE to Download the design in the way you want to use</h6>
            </div>
            <div
              className={`announcement-info-benefits ${
                isAuthenticated ? "signup-announcement-info-benefits" : ""
              }`}
            >
              <div
                className={`announcement-info-benefit-list ${
                  isAuthenticated ? "signup-announcement-info-benefit-list" : ""
                }`}
              >
                <p>
                  <FaRegSquareCheck className="announcement-benefit-list-icon" />{" "}
                  Unlimited projects
                </p>
                <p>
                  <FaRegSquareCheck className="announcement-benefit-list-icon" />{" "}
                  Drag and drop design
                </p>
                <p>
                  <FaRegSquareCheck className="announcement-benefit-list-icon" />{" "}
                  Draw customized floor plan
                </p>
              </div>
              <div
                className={`announcement-info-benefit-list ${
                  isAuthenticated ? "signup-announcement-info-benefit-list" : ""
                }`}
              >
                <p>
                  <FaRegSquareCheck className="announcement-benefit-list-icon" />{" "}
                  Access to all models and designing tools
                </p>
                <p>
                  <FaRegSquareCheck className="announcement-benefit-list-icon" />{" "}
                  Get non-customizable quotation
                </p>
                <p>
                  <FaRegSquareCheck className="announcement-benefit-list-icon" />{" "}
                  FREE 3D downloads
                </p>
              </div>

              <div
                className={`announcement-info-benefit-list ${
                  isAuthenticated ? "signup-announcement-info-benefit-list" : ""
                }`}
              >
                <p>
                  <FaRegSquareCheck className="announcement-benefit-list-icon" />{" "}
                  Design from home
                </p>
                <p>
                  <FaRegSquareCheck className="announcement-benefit-list-icon" />{" "}
                  Real-life like immersive designing in 3D
                </p>
                <p>And many more...</p>
              </div>
            </div>
            <div
              className={`announcement-info-summary ${
                isAuthenticated ? "signup-announcement-info-summary" : ""
              }`}
            >
              The ONE and ONLY platform in India where interior project
              planning, designing, and budgeting are done at leisure from home
              for FREE.
            </div>
          </div>
        </section>
      )}

      {isAuthenticated && (
        <section className="membership-section">
          <div className="membership-heading">
            <IoIosCheckmarkCircle className="membership-type-icon" />
            <p className="membership-type">Classic Membership</p>
          </div>

          <section className="membership-details-container">
            <section className="membership-details-content">
              <div className="membership-details">
                <div className="mobile-membership-heading">
                  <p className="membership-type">Classic Membership</p>
                  <IoIosCheckmarkCircle className="membership-type-icon" />
                </div>
                <h2 className="membership-title">Interioverse’s Classic</h2>
                <p className="membership-subtitle">
                  Design your space yourself
                </p>
                <p className="membership-price">
                  <span>{`₹${price || 499}/-`}</span> per month
                </p>
                <p className="membership-description">
                  With classic membership, explore more features and
                  functionalities.
                </p>
                <div className="membership-benefits">
                  <p>
                    <FaRegSquareCheck className="announcement-benefit-list-icon" />{" "}
                    Make customizable quotation
                  </p>
                  <p>
                    <FaRegSquareCheck className="announcement-benefit-list-icon" />{" "}
                    Execution Commission of 7%
                  </p>
                  <p>
                    <FaRegSquareCheck className="announcement-benefit-list-icon" />{" "}
                    Self sale - Movable Furniture Royalty of 2%
                  </p>
                  <p>
                    <FaRegSquareCheck className="announcement-benefit-list-icon" />{" "}
                    Discount of additional 2% on materials
                  </p>
                </div>
                <p className="membership-or">Or</p>

                <p className="membership-referral">
                  Refer Interioverse to other designers and get classic
                  membership free of cost.
                  
                </p>
                <p
                  className="membership-read-more"
                  onClick={() => setReferPopup(true)}
                >
                  Read More
                </p>

                <div className="membership-quotation-days-section">
                  <div className="membership-quotation-details-parent">
                    <div className="membership-quotation-details-child"></div>
                    <div className="membership-quotation-details-item"></div>
                    <div className="membership-quotation-details-inner"></div>
                    <div className="membership-quotation-details-div"></div>
                    <div className="membership-quotation-days">{count}</div>
                  </div>
                  <div className="membership-quotation-days-remaining">
                    Days remaining
                  </div>
                </div>
                <button
                  className="buy-now-button"
                  onClick={() => navigate("/payment")}
                >
                  Buy Now
                </button>
              </div>

              {referPopup && (
                <div className="referral-instructions-content">
                  <div className="referral-instructions-details">
                    <div className="referral-close">
                      <IoMdClose
                        className="referral-close-icon"
                        onClick={() => setReferPopup(false)}
                      />
                    </div>

                    <div className="referral-instructions">
                      <p className="referral-details">
                        In order to claim the referral benefit,
                      </p>
                      <p className="referral-details">
                        Ask the user to put your Profile ID as a referral code
                        in the purchase page.
                      </p>
                      <p className="referral-details">
                        1 Signup = Get 6 months FREE Classic Membership for
                        yourself. Your Referral gets 3 Months Free Classic
                        membership.
                      </p>
                      <p className="referral-details">
                        2 Signups = Extend FREE classic membership to one year.
                      </p>
                      <p className="referral-details">
                        3 Signups = Add one more FREE year to your classic
                        membership, and so on...
                      </p>

                      <p className="referral-details">
                        Click here for{" "}
                        <span onClick={() => navigate("/my-profile")}>
                          Profile ID
                        </span>{" "}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </section>

            <section className="membership-details-content">
              <div className="membership-details">
                <div className="mobile-membership-heading">
                <IoIosCheckmarkCircle className="membership-type-icon" />
                  <p className="membership-type">Classic Membership</p>
                 
                </div>
                <h2 className="membership-title">Interioverse’s Enterprise</h2>
                <p className="membership-subtitle">
                  Quotation making for teams !
                </p>
                <p className="membership-price">
                  Starting as low as <span>{`₹${208}/-`}</span> per users
                </p>
                <p className="membership-description">
                  With Enterprise Module, explore more features and
                  functionalities.
                </p>
                <div className="membership-benefits">
                  <p>
                    <FaRegSquareCheck className="announcement-benefit-list-icon" />{" "}
                    One Template, Many Users
                  </p>
                  <p>
                    <FaRegSquareCheck className="announcement-benefit-list-icon" />{" "}
                    No More Pricing Discrepancy
                  </p>
                  <p>
                    <FaRegSquareCheck className="announcement-benefit-list-icon" />{" "}
                    Track all Your Quotation in One Place
                  </p>
                  <p>
                    <FaRegSquareCheck className="announcement-benefit-list-icon" />{" "}
                    Easily Streamline Your Quotation Across all Functions
                  </p>
                </div>
                
                <button
                  className="buy-now-button"
                  onClick={() => navigate("/create-organization")}
                >
                  Buy Now
                </button>
              </div>

           
            </section>
          </section>
        </section>
      )}
    </article>
  );
}

export default Pricing;
