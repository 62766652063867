import React, { useState } from "react";
import "./reassigningProject.css";
import A1 from "../../../assets/profile_avatars/A1.png";
import A2 from "../../../assets/profile_avatars/A2.png";
import A3 from "../../../assets/profile_avatars/A3.png";
import A4 from "../../../assets/profile_avatars/A4.png";
import A5 from "../../../assets/profile_avatars/A5.png";
import A6 from "../../../assets/profile_avatars/A6.png";
import { IoMdClose } from "react-icons/io";
import AXIOS from "../../../services/axios";

function ReassigningProject({
  setIsPopUp,
  reassigning,
  TeamMembersImg,
  fetchTeamProjectDetails,
}) {
  const [isVerifyMember, setIsVerifyMember] = useState({
    isVerified: false,
    userId: "",
    name: "",
    email: "",
    role: "",
    phone_number: "",
    profile_picture: "",
    projects_count:""
  });
  const [message, setMessage] = useState("");
  const [memberId, setMemberId] = useState("");

  const handleChange = (e) => {
    const { value } = e.target;
    setMemberId(value);
    setMessage("");
    setIsVerifyMember({
      isVerified: false,
      userId: "",
      name: "",
      email: "",
      role: "",
      phone_number: "",
      profile_picture: "",
    });
  };

  const handleVerifyMember = async (e) => {
    e.preventDefault();
    try {
      if (!memberId) {
        setMessage("User ID is required");
        return;
      }

      const response = await AXIOS.post(
        "/organization/verify-project-reassigning-member", // Update this URL to match your backend endpoint
        { memberId },
        { withCredentials: true } // Ensures cookies are sent along with the request
      );

      if (response.status === 200) {
        const memberData = response.data.member;

        // Update state with isVerified set to true
        setIsVerifyMember((prevState) => ({
          ...prevState,
          isVerified: true,
          ...memberData,
        }));
      }
    } catch (error) {
      if (error.response) {
        if (
          error.response.status === 404 ||
          error.response.status === 409 ||
          error.response.status === 403
        ) {
          setMessage(error.response.data.message);
        }
      }
    }
  };

  const handleReassign = async (e) => {
    e.preventDefault();
    try {
      if (!isVerifyMember.userId) {
        return;
      }

      const response = await AXIOS.post(
        "/organization/change-project-assignment", // Update this URL to match your backend endpoint
        { projectId: reassigning.projectId, newUserId: isVerifyMember.userId },
        { withCredentials: true } // Ensures cookies are sent along with the request
      );

      if (response.status === 200) {
        fetchTeamProjectDetails();
        setIsVerifyMember({
          isVerified: false,
          userId: "",
          name: "",
          email: "",
          role: "",
          phone_number: "",
          profile_picture: "",
        });
        setMemberId("");
        setIsPopUp((prev) => ({
          ...prev,
          reassigning: {
            projectId: "",
            projectName: "",
            assignedUser: "",
          },
          viewVersions: false,
        }));
      }
    } catch (error) {
      if (error.response) {
        if (
          error.response.status === 404 ||
          error.response.status === 409 ||
          error.response.status === 403||
          error.response.status === 400
        ) {
          console.log(error.response.data);
          
          setMessage(error.response.data.error);
        }
      }
    }
  };

 

  return (
    <div className="edit-project">
      <div className="re-assigning-project-container">
        <IoMdClose
          className="close-popup"
          onClick={() =>
            setIsPopUp((prev) => ({
              ...prev,
              reassigning: {
                ...prev.reassigning,
                projectId: "",
                projectName: "",
                assignedUser: "",
              },
            }))
          }
        />
        <div className="new-project-content">
          <h2 className="payment-title">Re-assign Project</h2>

          <section className="re-assigning-project-section">
            <section className="re-assigning-project-details">
              <div className="profile-project-info">
                <h2 className="profile-project-title">
                  {reassigning.projectName}
                </h2>
                <p className="project-aassigned-to">
                  Assigned to <p>{reassigning.assignedUser}</p>
                </p>
              </div>
              <div className="re-assign-member-section">
                <label className="add-member-label">Re-assign to</label>

                <form
                  className="re-assign-member-input-group"
                  onSubmit={(e) => {
                    if (!isVerifyMember.isVerified) {
                      handleVerifyMember(e);
                    } else {
                      e.preventDefault();
                    }
                  }}
                >
                  <input
                    type="text"
                    placeholder="Enter Profile ID"
                    className="add-member-profile-id-input"
                    spellCheck="false"
                    required
                    onChange={handleChange}
                    value={memberId}
                  />
                  <button
                    className={`added-member-verify-btn ${
                      isVerifyMember.isVerified
                        ? "added-member-verified-btn"
                        : ""
                    }`}
                    type="submit"
                  >
                    {isVerifyMember.isVerified ? "Verified" : "Verify"}
                  </button>
                </form>

                {message && <p className="error">{message}</p>}
              </div>
            </section>

            <section className="re-assigning-member-details">
              <div className="member-detail re-assigning-member">
                {isVerifyMember.isVerified ? (
                  <>
                    <figure className="member-profile">
                      <img
                        src={
                          TeamMembersImg[isVerifyMember.profile_picture] || A1
                        }
                        alt="member"
                        className="member-image"
                      />
                      <figcaption className="member-info">
                        <p className="member-id">{isVerifyMember.name}</p>
                        <p className="member-id">{isVerifyMember.userId}</p>
                        <p className="member-email">{isVerifyMember.email}</p>
                        <p className="member-contact">
                          {isVerifyMember.phone_number}
                        </p>
                      </figcaption>
                    </figure>

                    <h3 className="member-quotation-title">
                      No of Projects: {isVerifyMember.projects_count}
                    </h3>
                    <button
                      className="added-member-btn"
                      onClick={handleReassign}
                    >
                      Assign
                    </button>
                  </>
                ) : (
                  <div className="verify-re-assigning-member-text">
                    <p>
                      Please verify the member's ID before proceeding with the
                      re-assignment of the project.
                    </p>
                  </div>
                )}
              </div>
            </section>
          </section>
        </div>
      </div>
    </div>
  );
}

export default ReassigningProject;
