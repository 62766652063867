import React, { useEffect, useState } from "react";
import "./book-a-service.css";
import map from "../../assets/service_map.jpg";
import landingPageImg from "../../assets/Landing_page_picture.jpg";
import { useNavigate } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import AXIOS from "../../services/axios";
import Loading from "../../components/loading/loading";
import { formatIndianNumber } from "../../services/reusableCodes";
import CustomDropdown from "../../components/book-a-service-dropdown/book-a-service-dropdown";

function BookService() {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState({
    process: false,
    sent: false,
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const projectsData = async () => {
      try {
        const response = await AXIOS.get("/project/projects-to-book-service", {
          withCredentials: true,
        });
        if (response.status === 200) {
          setProjects(response.data.projects);
          setSelectedProject(response.data.projects[0] || null);
          console.log(response.data.projects[0]);
          
          setSelectedProject({
            projectLocation: response.data.projects[0].project_location || "",
            selectedProjectName:response.data.projects[0].project_name || "",
            selectedVersionName : response.data.projects[0].versions[0].version_name || "",
            selectedVersionCommission: response.data.projects[0].versions[0].commission || 0,
          });

          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    projectsData();
  }, []);

  const handleProjectChange = (selectedProjectId, selectedVersionName) => {
    // Find the selected project based on project_id
    const selectedProject = projects.find(
      (project) => project.project_id === selectedProjectId
    );
  
    if (selectedProject) {
      // Find the selected version within the selected project
      const selectedVersion = selectedProject.versions.find(
        (version) => version.version_name === selectedVersionName
      );
  
      // Check if the selected version exists
      if (selectedVersion) {
        // Update state with selected project location and version
        setSelectedProject({
          projectLocation: selectedProject.project_location,
          selectedProjectName:selectedProject.project_name,
          selectedVersionName : selectedVersion.version_name,
          selectedVersionCommission: selectedVersion.commission,
        });
        setIsDropdownOpen(false);
      } else {
        // If no version is found, reset the state
        setSelectedProject(null);
      }
    } else {
      // If no project is found, reset the state
      setSelectedProject(null);
    }
  };

  const handleSendMail = async (request) => {
    try {
      setEmail((prev) => ({ ...prev, process: true }));
      let response;
      if (request === "measurement") {
        response = await AXIOS.post("/email/send-site-measurement-request");
      } else {
        response = await AXIOS.post("/email/send-project-execution-request");
      }

      if (response && response.status === 200) {
        setEmail((prev) => ({ ...prev, process: false }));
        setEmail((prev) => ({ ...prev, sent: true }));
        setTimeout(() => {
          setEmail((prev) => ({ ...prev, sent: false }));
        }, 2000);
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <section className="service-booking-container">
      <IoMdClose className="service-close-icon" onClick={() => navigate("/")} />
      <img src={landingPageImg} alt="" className="cover-img" />
      <div className="service-booking">
        <div>
          <div className="service-booking-form">
            <h1 className="service-booking-title">Book a Service</h1>
            <div className="service-form-container">
              <div className="service-form-section">
                <div className="service-city-list">
                  <p className="service-city">Bangalore</p>
                  <p className="service-city">Hyderabad</p>
                  <p className="service-city">Chennai</p>
                  <p className="service-city">Pune</p>
                </div>
                <div className="service-form-group">
                  <label htmlFor="project_name" className="service-form-label">
                    Project Name
                  </label>
                  
                  <CustomDropdown
                    projects={projects}
                    selectedProject={selectedProject}
                    handleProjectChange={handleProjectChange}
                    isDropdownOpen = {isDropdownOpen} 
                    setIsDropdownOpen = {setIsDropdownOpen}
                  />
                </div>
                <div className="service-map-container">
                  <img src={map} alt="Map" className="map-image" />
                </div>
              </div>
              <div className="service-project-details">
                <p className="service-project-location">
                  Project Location:{" "}
                  <span className="service-location">
                    {selectedProject?.projectLocation || ""}
                  </span>
                </p>
                <div className="service-price-details">
                  <div className="service-price-item">
                    <p className="service-price-label">
                      Your earning from this project:
                    </p>
                    <h2 className="service-earnings-value">
                      {"₹ " +
                        formatIndianNumber(selectedProject?.selectedVersionCommission || "0")}
                    </h2>
                  </div>
                </div>

                <div className="service-interioverse-provides">
                  <label
                    htmlFor="interioverse_provides"
                    className="service-provides-label"
                  >
                    Interioverse provides:
                  </label>
                  <ul className="service-provides-list">
                    <li className="service-provides-item">
                      Professional project Execution
                    </li>
                    <li className="service-provides-item">
                      Proper Time Tracking for Project
                    </li>
                    <li className="service-provides-item">Dedicated CRM</li>
                    <li className="service-provides-item">
                      Dedicated Project Manager
                    </li>
                    <li className="service-provides-item">
                      4 free Annual Service
                    </li>
                  
                  </ul>
                </div>
              </div>
            </div>
            <div className="service-booking-buttons">
             
              {!email.process && !email.sent && (
                <button
                  className="service-booking-button"
                  onClick={() => handleSendMail("execution")}
                >
                  <p className="service-button-text">Book</p>
                  <p className="service-button-subtext">Project Execution</p>
                </button>
              )}

              {email.sent && (
                <button className="service-booking-button email-sent-for-service">
                  <p className="service-button-text">Request</p>
                  <p className="service-button-subtext">Sent successfully</p>
                </button>
              )}

              {email.process && (
                <button className="service-booking-button email-sent-for-service">
                  <p className="service-button-text">Request</p>
                  <p className="service-button-subtext">Sending</p>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BookService;
