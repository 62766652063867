import React, { useContext, useEffect, useRef, useState } from "react";
import landingPageImg from "../../../assets/Landing_page_picture.jpg";
import Sidebar from "../../../components/sidebar_newquotation/sideBar";
import { useLocation, useNavigate, useParams, UNSAFE_NavigationContext } from "react-router-dom";
import AXIOS from "../../../services/axios";
import {
  checkForWarnings,
  generateTable,
  intialRateTable,
} from "../../../services/reusableCodes";
import { v4 as uuidv4 } from "uuid";
import Loading from "../../../components/loading/loading";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import CustomTextBox from "../../../components/customizedQuilTexBox/CustomTextBox";
import { MdOutlineTextFields } from "react-icons/md";
import MyQuotationTable from "../my-quotation/my-quotation-table";
import "./download-quotation.css";
import topLeft from "../../../assets/pageTopLeft.png";
import bottomLeft from "../../../assets/pageBottomLeft.png";
import topRight from "../../../assets/pageTopRight.png";
import bottomRight from "../../../assets/pageBottomRight.png";
import coverImg from "../../../assets/quotationCoverImg.jpg";
import logo from "../../../assets/logo.png";
import interioverse_logo from "../../../assets/interioverse_logo.png";
import { useSelector } from "react-redux";
import MobileScreenMessage from "../../../components/mobileScreenMessage/mobileScreenMessage";
// import useBackListener from "../../../services/useBackListener";

function DownloadQuotation() {
  const [color, setColor] = useState({
    background_color: "#fff7ef",
    border_color: "#fff7ef",
    table_header: "#f16f40",
    table_font: "#ffffff",
    table_border: "#f16f40",
    table_amount: "#ffffff",
    amount_font: "#636363",
  });
  const [selectedFontFamily, setSelectedFontFamily] = useState("Montserrat");
  const { projectId,version } = useParams();
  const initialTable = generateTable();
  const initialRateTable = intialRateTable(initialTable);
  const [areaTables, setAreaTables] = useState([initialTable]);
  const [rateTables, setRateTables] = useState([initialRateTable]);
  const [subTotalTables, setSubTotalTables] = useState({
    subTotal: "0",
    total: "0",
    taxableAmount: "0",
    gst: {
      name: "",
      percentage: "",
      amount: "0",
    },
    add: [{ id: uuidv4(), name: "", percentage: "", amount: "0" }],
    minus: [{ id: uuidv4(), name: "", percentage: "", amount: "0" }],
  });
  const [summary, setSummary] = useState({
    area: [
      {
        name: "",
        value: "",
      },
    ],
    subtotal: "",
    total: "",
  });
  const [updateSave, setUpdateSave] = useState(false);
  const [interioverseRate, setInterioversRate] = useState({
    total: "",
    gstTotal: "",
    mainTotal: "",
    InterioverseGst: "",
    userRange: "",
    maxRange: "",
    designerCommision: "",
  });
  const [loading, setLoading] = useState(true);
  const [isCityFound, setIsCityFound] = useState(false);
  const [editorPages, setEditorPages] = useState([
    {
      id: uuidv4(),
      editorRef: React.createRef(),
      quillRef: React.createRef(),
      textBox: [],
    },
  ]);
  const [tablePages, setTablePages] = useState([]);
  const [initialPages, setInitialPages] = useState([]);
  const [initialBoxes, setInitialBoxes] = useState([]);
  const [pageContentData, setPageContentData] = useState({
    content: [],
    pageMerge: "",
  });
  const [user, setUser] = useState({
    cityName: "",
    brand_name: "",
    registered_name: "",
    email: "",
    phone_number: "",
    tag_line: "",
    brand_logo: null,
    otherCategoryCount: "",
  });
  const isMobile= useSelector((state) => state.isMobileScreen.isMobile);
  const navigate = useNavigate();
  const [appliedColumns, setAppliedColumns] = useState(
    new Set(["Height", "Width"])
  );
  const currentAccountType = useSelector(
          (state) => state.auth.currentAccountType
        );

  // const navigation = useContext(UNSAFE_NavigationContext).navigator;
  // const navigate = useNavigate();
  // const listenerRef = useRef(null); // Ref to store the unlisten function

  // useEffect(() => {
  //   if (!listenerRef.current) {
  //     console.log("Adding listener");

  //     // Add the listener and store the unlisten function in the ref
  //     listenerRef.current = navigation.listen((locationListener) => {
  //       if (locationListener.action === "POP") {
  //         // Do your stuff on back button click
  //         navigate("/");
  //       }
  //     });

  //     // Cleanup function to remove the listener
  //     return () => {
  //       if (listenerRef.current) {
  //         console.log("Removing listener");
  //         listenerRef.current(); // Call the unlisten function
  //         listenerRef.current = null; // Clear the ref
  //       }
  //     };
  //   }
  // }, [navigation, navigate]); 

  // useEffect(() => {
  //   window.history.pushState({ page: 'myQuotation' }, '', `/my-quotation/${projectId}`);
  // }, []);
  
  const checkCity = async () => {
    try {
      const response = await AXIOS.get(`/${
          currentAccountType === "organization" 
            ? "organization":"project"}/check-city/${projectId}`);
      if (response.status === 200) {
        setIsCityFound(true);
      }
    } catch (error) {
      if (error.response.status === 200) {
        setIsCityFound(false);
      }
    }
  };


  useEffect(() => {
    const handleGetTableData = async () => {
      try {
        const response = await AXIOS.get(
          `/${
            currentAccountType === "organization" 
              ? "organization":"user-template"}/interioverse-template/${projectId}/${version}`
        );

        if (response && response.status === 200) {
          const {
            user_template,
            city_template,
            brand_name,
            registered_name,
            email,
            phone_number,
            tag_line,
            brand_logo,
            otherCategoryCount,
          } = response.data;

          if (city_template && city_template.content) {
            const pagesContent = city_template.content;
            const pageToMerge = parseInt(city_template.pageToMerge, 10);

            const mapPageContent = (pages, pageToMerge) =>
              pages.slice(0, pageToMerge).map((page) => ({
                id: page.id,
                editorRef: React.createRef(),
                quillRef: React.createRef(),
                textBox: page.textbox.map((box) => ({
                  id: box.id,
                  textBoxRef: React.createRef(),
                  quillRef: React.createRef(),
                  position: JSON.parse(box.position),
                  dragging: { current: JSON.parse(box.dragging) },
                  offset: { current: JSON.parse(box.offset) },
                  resize: box.resize,
                  image: JSON.parse(box.image),
                  width: box.width,
                  height: box.height,
                  zIndex: JSON.parse(box.zIndex),
                  fontSize: box.fontSize,
                  content: "",
                  isTextareaActive: false,
                })),
              }));

            const newEditorPages = mapPageContent(pagesContent, pageToMerge);
            const newTablePages = mapPageContent(
              pagesContent,
              pagesContent.length
            ).slice(pageToMerge);

            setEditorPages(newEditorPages);
            setTablePages(newTablePages);
            setInitialPages([...newEditorPages, ...newTablePages]);

            const boxes = [...newEditorPages, ...newTablePages].flatMap(
              (page) => page.textBox
            );
            setInitialBoxes(boxes);

            setPageContentData((prev) => ({
              ...prev,
              content: pagesContent,
              pageMerge: pageToMerge,
            }));
          }

          if (user_template) {
            const newSubTotalTables = {
              subTotal: user_template.user_subTotal || "0",
              total: user_template.user_total || "0",
              taxableAmount: user_template.user_taxableAmount || "0",
              gst: user_template.user_gst || {
                name: "",
                percentage: "",
                amount: "0",
              },
              add: user_template.user_add || [
                { id: uuidv4(), name: "", percentage: "", amount: "0" },
              ],
              minus: user_template.user_minus || [
                { id: uuidv4(), name: "", percentage: "", amount: "0" },
              ],
            };

            const newAreaTables = (user_template.template || []).map(
              (table) => ({
                id: table.id || uuidv4(),
                area: (table.areas || []).map((area) => ({
                  id: area.area_id || uuidv4(),
                  areaValue: area.name || "",
                  amount: area.amount || "",
                  items: (area.items || []).map((item) => ({
                    id: item.item_id || uuidv4(),
                    itemValue: item.name || "",
                    descriptionValue: item.description || "",
                    amount: item.amount || "",
                    subItems: (item.subItems || []).map((subItem) => ({
                      id: subItem.name_id || uuidv4(),
                      subItemValue: subItem.name || "",
                      amount: subItem.amount || "",
                      category: subItem.category || "",
                      finish: subItem.finish || "",
                      type: subItem.type || "",
                      height: subItem.height || "",
                      width: subItem.width || "",
                      unit: subItem.unit || "",
                      range: subItem.range || "",
                      rate: subItem.rate || "",
                      warning : subItem.warning || "",
                    })),
                  })),
                })),
              })
            );

            setColor({
              background_color: city_template.background_color || "#fff7ef",
              table_header: city_template.table_header || "#f16f40",
              table_font: city_template.table_font || "#ffffff",
              table_border: city_template.table_border || "#f16f40",
              table_amount: city_template.table_amount || "#ffffff",
              amount_font: city_template.amount_font || "#636363",
            });
            setSelectedFontFamily(city_template.font_family || "Montserrat");
            setUser({
              cityName: city_template.name
                ? city_template.name.toUpperCase()
                : "",
              brand_name: brand_name ? brand_name.toUpperCase() : "",
              registered_name: registered_name
                ? registered_name.toUpperCase()
                : "",
              email: email || "",
              phone_number: phone_number || "",
              tag_line: tag_line || "",
              brand_logo: brand_logo || null,
              otherCategoryCount: otherCategoryCount ? otherCategoryCount : 0,
            });
            setSubTotalTables(newSubTotalTables);

            if (checkForWarnings(newAreaTables)) {
              navigate(`/new-project/${projectId}/${version}`)
              return
            }  
            setAreaTables(newAreaTables);
          }
        }
      } catch (error) {
        console.log("Error fetching data", error);
      } finally {
        setLoading(false);
      }
    };

    if (!isMobile) {
      handleGetTableData();
    }else{
      setLoading(false)
    }

    checkCity()
  }, []);


  useEffect(() => {
    if (initialPages) {
      multiInitializeEditors(initialPages);
    }
    if (initialBoxes) {
      multiInitializeTextBox(initialBoxes);
    }
  }, [initialPages, initialBoxes]);

  const multiInitializeEditors = (pages) => {
    pages.forEach((page) => {
      if (page.editorRef.current && !page.quillRef.current) {
        const quill = new Quill(page.editorRef.current, {
          theme: "snow",
          modules: {
            toolbar: {
              container: `#toolbar-${page.id}`,
              handlers: {
                customTextBox: () =>
                  quill.getModule("customTextBox").showTextBox(),
              },
            },
            customTextBox: {
              showTextBox: (range) => {
                const bounds = quill.getBounds(range);
              },
            },
          },
        });

        page.editorRef.current.setAttribute("spellinitialPages", "false");
        page.quillRef.current = quill;
      }
    });
  };

  const multiInitializeTextBox = (boxes) => {
    boxes.forEach((box) => {
      if (box.textBoxRef.current && !box.quillRef.current) {
        const Font = Quill.import("formats/font");
        Font.whitelist = ["mirza", "roboto", "serif"];
        Quill.register(Font, true);

        const quill = new Quill(box.textBoxRef.current, {
          theme: "snow",
          modules: {
            toolbar: {
              container: `#textBox-${box.id}`,
            },
          },
        });

        box.textBoxRef.current.setAttribute("spellinitialPages", "false");
        box.quillRef.current = quill;
      }
    });
  };

  useEffect(() => {
    const tableData = async () => {
      try {
        if (
          pageContentData.content &&
          Array.isArray(pageContentData.content) &&
          pageContentData.content.length > 0
        ) {
          const pagesContent = pageContentData.content;
          const pageToMerge = parseInt(pageContentData.pageMerge, 10);

          // Update editorPages
          editorPages.forEach((prevPage) => {
            const matchedPage = pagesContent
              .slice(0, pageToMerge)
              .find((page) => page.id === prevPage.id);
            if (matchedPage) {
              if (prevPage.quillRef.current) {
                prevPage.quillRef.current.root.innerHTML =
                  matchedPage.quillData;
              }

              prevPage.textBox.forEach((prevBox) => {
                const matchedBox = matchedPage.textbox.find(
                  (box) => box.id === prevBox.id
                );
                if (matchedBox) {
                  if (prevBox.quillRef.current) {
                    prevBox.quillRef.current.root.innerHTML =
                      matchedBox.quillData;
                  }
                }
              });
            }
          });

          // Update tablePages
          tablePages.forEach((prevPage) => {
            const matchedPage = pagesContent
              .slice(pageToMerge)
              .find((page) => page.id === prevPage.id);
            if (matchedPage) {
              if (prevPage.quillRef.current) {
                prevPage.quillRef.current.root.innerHTML =
                  matchedPage.quillData;
              }

              prevPage.textBox.forEach((prevBox) => {
                const matchedBox = matchedPage.textbox.find(
                  (box) => box.id === prevBox.id
                );
                if (matchedBox) {
                  if (prevBox.quillRef.current) {
                    prevBox.quillRef.current.root.innerHTML =
                      matchedBox.quillData;
                  }
                }
              });
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    tableData();
  }, [initialPages, initialBoxes]);

  useEffect(() => {
    const newSummary = {
      area: areaTables.flatMap((table) =>
        (table.area || []).map((area) => ({
          name: area.areaValue || "",
          value: area.amount || "",
        }))
      ),
      subtotal: subTotalTables.subTotal || "0",
      total: subTotalTables.total || "0",
    };

    // Update the summary state
    setSummary(newSummary);
  }, [areaTables, rateTables]);

  if (loading) {
    return <Loading />;
  }

  if (isMobile) {
    return(
      <MobileScreenMessage />
    )
  }

  return (
    <div>
      <figure>
        <img
          src={landingPageImg}
          alt="Cover img"
          className="partner-cover-img"
        />
      </figure>
      <section className="quotation-template">
        <article className="quotation-template-container quotation-container">
          {editorPages.length > 0 && (
            <section
              className="editor-section"
              style={{
                margin: "0px",
              }}
            >
              <div className="pdf-text-editor" style={{zIndex:0}}>
                {" "}
                <div
                  className="pdf-text-editor-space"
                  style={{
                    backgroundColor: color.background_color,
                    padding: "0px",
                  }}
                >
                  <div className="first-page-quotation-content">
                    <div className="first-page-quotation-title">
                      <p className="first-page-quotation-text">QUOTATION</p>
                      <p className="first-page-quotation-text">FOR</p>
                      <h1 className="first-page-quotation-text">
                        INTERIOR WORKS
                      </h1>
                    </div>
                    <div className="first-page-quotation-logo">
                      <img
                        src={user.brand_logo || logo}
                        alt="Interioverse Logo"
                        className="first-page-logo-image"
                      />
                      <h2 className="first-page-brand-name">
                        {user.brand_name || "QUOTATION"}
                      </h2>
                    </div>
                    <div className="first-page-company-info">
                      <p className="first-page-quotation-company">
                        {user.registered_name || ""}
                      </p>
                      <p className="first-page-quotation-tagline">
                        {user.tag_line || ""}
                      </p>
                    </div>
                  </div>
                  <img src={coverImg} alt="" className="firstPageCoverImg" />
                </div>
                <div className="block-to-type"></div>
              </div>
              {editorPages.map((editor) => (
                <div key={editor.id} className="pdf-text-editor" style={{zIndex:0}}>
                  <div
                    className="pdf-text-editor-space"
                    style={{
                      backgroundColor: color.background_color,
                      padding: 0,
                    }}
                  >
                    {/* header  */}
                    <div className="page-header">
                      <div className="page-header-content">
                        <div className="page-header-user">
                        {user.brand_logo && (<img
                            src={user.brand_logo}
                            alt="Interioverse Logo"
                            className="page-header-user-logo"
                          />)}
                          {user.brand_name && (
                            <div>
                              <h2 className="page-header-user-brand-name">
                                {user.brand_name}
                              </h2>
                              <p className="page-header-user-tagline">
                                {user.tag_line}
                              </p>
                              <p className="page-header-user-phn">
                                {`+91 ${user.phone_number}`}
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="page-header-interioverse">
                          <img
                            src={logo}
                            alt="Interioverse Logo"
                            className="page-header-logo"
                          />
                          <p className="page-header-interioverse-name">
                            www.interioverse.com
                          </p>
                        </div>
                      </div>
                      <div className="page-header-images">
                        <div>
                          <img
                            src={topLeft}
                            alt=""
                            className="page-header-top-left-image"
                          />
                        </div>
                        <div>
                          <img
                            src={topRight}
                            alt=""
                            className="page-header-top-right-image"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="page-with-header-footer"
                      style={{ padding: "20px 30px" }}
                    >
                      {editor.textBox &&
                        editor.textBox.map((box) => (
                          <div
                            className="draggable-container"
                            key={box.id}
                            style={{ height: "auto" }}
                          >
                            <div
                              style={{
                                left: box.position.x,
                                top: box.position.y,
                                width: box.width ? box.width : "",
                                height: box.height ? box.height : "",
                                resize: "none",
                                border: "none",
                                padding: "0px",
                                zIndex: `${box.zIndex ? box.zIndex : 1}`,
                                fontSize: box.fontSize
                                  ? `${box.fontSize}px`
                                  : "13px",
                              }}
                              ref={box.textBoxRef}
                              className="pdf-text-box"
                              spellinitialPages={false}
                            ></div>
                            <div
                              id={`textBox-${box.id}`}
                              className="text-box-tools-container"
                              style={{
                                display: "none",
                              }}
                            ></div>
                          </div>
                        ))}
                      <div
                        ref={editor.editorRef}
                        className="pdf-text-enter-space"
                      ></div>
                    </div>
                    {/* footer */}
                    <div className="page-footer">
                      <div className="footer-content">
                        <div className="footer-user-contact">
                          <span>{`${user.email} | +91 ${user.phone_number}`}</span>
                        </div>
                        <div className="footer-user-company">
                          {`${
                            user.registered_name
                              ? user.registered_name + ","
                              : ""
                          } ${user.cityName || ""}`}
                        </div>
                      </div>
                      <div className="page-footer-images">
                        <div>
                          <img src={bottomLeft} alt="" />
                        </div>
                        <div>
                          <img src={bottomRight} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="block-to-type"></div>
                </div>
              ))}
            </section>
          )}

          <MyQuotationTable
            color={color}
            areaTables={areaTables}
            subTotalTables={subTotalTables}
            selectedFontFamily={selectedFontFamily}
            appliedColumns={appliedColumns}
          />

          {tablePages.length > 0 && (
            <section
              className="editor-section"
              style={{
                margin: "0px",
              }}
            >
              {tablePages.map((editor) => (
                <div key={editor.id} className="pdf-text-editor" style={{zIndex:0}}>
                  <div
                    className="pdf-text-editor-space"
                    style={{
                      backgroundColor: color.background_color,
                      padding: 0,
                    }}
                  >
                    {/* header  */}
                    <div className="page-header">
                      <div className="page-header-content">
                        <div className="page-header-user">
                        {user.brand_logo && (<img
                            src={user.brand_logo}
                            alt="Interioverse Logo"
                            className="page-header-user-logo"
                          />)}
                          {user.brand_name && (
                            <div>
                              <h2 className="page-header-user-brand-name">
                                {user.brand_name}
                              </h2>
                              <p className="page-header-user-tagline">
                                {user.tag_line}
                              </p>
                              <p className="page-header-user-phn">
                                {`+91 ${user.phone_number}`}
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="page-header-interioverse">
                          <img
                            src={logo}
                            alt="Interioverse Logo"
                            className="page-header-logo"
                          />
                          <p className="page-header-interioverse-name">
                            www.interioverse.com
                          </p>
                        </div>
                      </div>
                      <div className="page-header-images">
                        <div>
                          <img
                            src={topLeft}
                            alt=""
                            className="page-header-top-left-image"
                          />
                        </div>
                        <div>
                          <img
                            src={topRight}
                            alt=""
                            className="page-header-top-right-image"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="page-with-header-footer"
                      style={{ padding: "20px 30px" }}
                    >
                      {editor.textBox &&
                        editor.textBox.map((box) => (
                          <div
                            className="draggable-container"
                            key={box.id}
                            style={{ height: "auto" }}
                          >
                            <div
                              style={{
                                left: box.position.x,
                                top: box.position.y,
                                width: box.width ? box.width : "",
                                height: box.height ? box.height : "",
                                resize: "none",
                                border: "none",
                                padding: "0px",
                                zIndex: `${box.zIndex ? box.zIndex : 1}`,
                                fontSize: box.fontSize
                                  ? `${box.fontSize}px`
                                  : "13px",
                              }}
                              ref={box.textBoxRef}
                              className="pdf-text-box"
                              spellinitialPages={false}
                            ></div>
                            <div
                              id={`textBox-${box.id}`}
                              className="text-box-tools-container"
                              style={{
                                display: "none",
                              }}
                            ></div>
                          </div>
                        ))}
                      <div
                        ref={editor.editorRef}
                        className="pdf-text-enter-space"
                      ></div>
                    </div>
                    {/* footer */}
                    <div className="page-footer">
                      <div className="footer-content">
                        <div className="footer-user-contact">
                          <span>{`${user.email} | +91 ${user.phone_number}`}</span>
                        </div>
                        <div className="footer-user-company">
                          {`${
                            user.registered_name
                              ? user.registered_name + ","
                              : ""
                          } ${user.cityName || ""}`}
                        </div>
                      </div>
                      <div className="page-footer-images">
                        <div>
                          <img src={bottomLeft} alt="" />
                        </div>
                        <div>
                          <img src={bottomRight} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="block-to-type"></div>
                </div>
              ))}

              {/* last page  */}
              <div className="pdf-text-editor" style={{zIndex:0}}>
                {" "}
                <div
                  className="pdf-text-editor-space"
                  style={{
                    backgroundColor: color.background_color,
                    padding: 0,
                    marginTop: "20px",
                  }}
                >
                  {/* header  */}
                  <div className="page-header">
                    <div className="page-header-content">
                      <div className="page-header-user">
                      {user.brand_logo && (<img
                          src={user.brand_logo}
                          alt="Interioverse Logo"
                          className="page-header-user-logo"
                        />)}
                        {user.brand_name && (
                          <div>
                            <h2 className="page-header-user-brand-name">
                              {user.brand_name}
                            </h2>
                            <p className="page-header-user-tagline">
                              {user.tag_line}
                            </p>
                            <p className="page-header-user-phn">
                              {`+91 ${user.phone_number}`}
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="page-header-interioverse">
                        <img
                          src={logo}
                          alt="Interioverse Logo"
                          className="page-header-logo"
                        />
                        <p className="page-header-interioverse-name">
                          www.interioverse.com
                        </p>
                      </div>
                    </div>
                    <div className="page-header-images">
                      <div>
                        <img
                          src={topLeft}
                          alt=""
                          className="page-header-top-left-image"
                        />
                      </div>
                      <div>
                        <img
                          src={topRight}
                          alt=""
                          className="page-header-top-right-image"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="page-footer-content">
                    <p className="thank-you-message">
                      Thank You for Considering Us!
                    </p>
                    <p className="page-footer-description">
                      We are thrilled at the opportunity to transform your space
                      with our turnkey interior design solutions. Our team of
                      dedicated professionals ensures every aspect of your
                      project, from initial concept to final execution, is
                      handled with precision and care. With a keen eye for
                      detail and a commitment to quality, we bring your vision
                      to life, creating a harmonious blend of aesthetics and
                      functionality.
                    </p>
                    <p className="page-footer-description">
                      Powered by Interioverse, an interior tech hub, where your
                      satisfaction is the top priority, and we look forward to
                      collaborating with you to craft a space that truly
                      reflects your unique style and meets your needs.
                    </p>

                    {user.brand_name && (
                      <div className="page-footer-brand-section">
                        {user.brand_logo && (
                          <img
                            src={user.brand_logo}
                            alt="Interioverse Logo"
                            className="page-footer-brand-logo"
                          />
                        )}
                        <h2 className="page-footer-brand-name">
                          {user.brand_name}
                        </h2>
                      </div>
                    )}

                    {user.brand_name && (
                      <p className="page-footer-and-symbol">&</p>
                    )}
                    <p className="page-footer-execution-text">
                      Our Esteemed Execution Partner
                    </p>

                    <img
                      src={interioverse_logo}
                      alt="Execution Partner Logo"
                      className="page-footer-execution-partner-logo"
                    />
                  </div>

                  {/* footer */}
                  <div className="page-footer">
                    <div className="footer-content">
                      <div className="footer-user-contact">
                        <span>{`${user.email} | +91 ${user.phone_number}`}</span>
                      </div>
                      <div className="footer-user-company">
                        {`${
                          user.registered_name ? user.registered_name + "," : ""
                        } ${user.cityName || ""}`}
                      </div>
                    </div>
                    <div className="page-footer-images">
                      <div>
                        <img src={bottomLeft} alt="" />
                      </div>
                      <div>
                        <img src={bottomRight} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="block-to-type"></div>
              </div>
            </section>
          )}
        </article>

        <Sidebar
          interioverseRate={interioverseRate}
          summary={summary}
          updateSave={updateSave}
          areaTables={areaTables}
          subTotalTables={subTotalTables}
          setUpdateSave={setUpdateSave}
          color={color}
          font_family={selectedFontFamily}
          projectId={projectId}
          version={version}
          isCityFound={isCityFound}
          maxRange={interioverseRate.maxRange}
          otherCategoryCount={user.otherCategoryCount}
          
        />
      </section>

      {editorPages.concat(tablePages).map((page) => (
        <div
          className="pdf-editor-text-header"
          key={page.id}
          style={{
            display: "none",
          }}
        >
          <p className="pdf-editor-text-title">Page Editor</p>
          <div id={`toolbar-${page.id}`}>
            <button className="ql-customTextBox">
              <MdOutlineTextFields />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default DownloadQuotation;
