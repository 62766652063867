import React, { useEffect, useRef, useState } from "react";
import "./page_editor_media.css";
import { IoMdClose } from "react-icons/io";
import { GoPlus } from "react-icons/go";
import { MdDelete } from "react-icons/md";
import AXIOS from "../../services/axios";

function PageEditorMedia({
  setIsMedia,
  isMedia,
  textBoxImage,
  activePage,
  handleDeleteTextBox
}) {
  const mediaInputRef = useRef();
  const [photos, setPhotos] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [sizeErr, setSizeErr] = useState(false);
  const [deleteFile,setDeletFile]= useState('')

  const fetchPhotos = async () => {
    try {
      const response = await AXIOS.get("/aws/get-photos");
      setPhotos(response.data.photos.reverse()); // Assuming the API returns the photo data
    } catch (err) {
      console.error("Error fetching photos:", err);
    }
  };

  useEffect(() => {
    fetchPhotos();
  }, []);

  const handleUploadMediaClick = () => {
    if (mediaInputRef.current) {
      mediaInputRef.current.click();
    }
  };

  const handleDeletePhotos=  async (url) => {
    if (!url) {
      return;
    }

    try {
      const response = await AXIOS.post("/aws/delete-photo", {url});

      if (response.status === 200) {
        setDeletFile('')
        fetchPhotos()
      }
    } catch (error) {
      console.error("Error uploading file", error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    const maxSizeInMB = 1;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

    if (file.size > maxSizeInBytes) {
      setSizeErr(true);
      setTimeout(() => {
        setSizeErr(false);
      }, 3000);
      return;
    }

    setSelectedFile(file);
  };

  const handleSelectPhoto = (photoUrl) => {
    if (isMedia.quillRef && isMedia.quillRef.current) {
     
      
      const quill = isMedia.quillRef.current;
      let range = quill.getSelection();
  
      // If no selection is made, place the cursor at the end of the editor's content
      if (!range) {
        range = {
          index: quill.getLength(), // Position at the end of the content
          length: 0,
        };
      }
  
      // Create an image element to get the dimensions
      const img = new Image();
      img.onload = () => {
        let width = `${img.width}px`;
        let height = `${img.height}px`;
        let calWidth = img.width
        let calHeight = img.height
  
        // Check if the image exceeds the maximum dimensions
        const maxWidth = 400;
        const maxHeight = 400;
  
        // Scale the dimensions proportionally if necessary
        if (calWidth > maxWidth || calHeight > maxHeight) {
          const widthRatio = maxWidth / calWidth;
          const heightRatio = maxHeight / calHeight;
          const scale = Math.min(widthRatio, heightRatio);
  
          width = `${calWidth * scale}px` ;
          height = `${calHeight * scale}px`;
        }
  
        quill.focus();
        quill.insertEmbed(range.index, "image", photoUrl); // Insert selected image into the editor
        quill.insertText(range.index + 1, "\u200B"); // Insert zero-width space
        // quill.setSelection(range.index + 1);
  
        // Update the box with image dimensions if necessary
        if (isMedia.image === false) {

          textBoxImage(isMedia.id, width, height);
          
        }
  
        setIsMedia(null); // Reset the box after selection
  
        // Remove <p><br></p> elements directly from the editor DOM
        const editorContainer = quill.root; // Get the root container of the editor

      // Find all <p> elements and filter ones that only contain <br>
      const paragraphs = editorContainer.querySelectorAll("p");

      paragraphs.forEach((paragraph) => {
        if (
          paragraph.childNodes.length === 1 &&
          paragraph.childNodes[0].nodeName === "BR"
        ) {
          paragraph.remove(); // Remove the empty paragraph
        }
      });
      };
  
      img.src = photoUrl; 
      quill.disable();
    }
  };
  
  
  const handleUpload = async () => {
    if (!selectedFile) {
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await AXIOS.post("/aws/upload-photo", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        setSelectedFile("");
        fetchPhotos();
      }
    } catch (error) {
      console.error("Error uploading file", error);
    }
  };

  return (
    <section className="media-popup">
       {deleteFile && (
        <section className="version-delete-popup">
          <div className="version-delete-container">
            <div className="version-delete-content">
              <p className="version-delete-label">{`Do you want to delete image?`}</p>
              <div className="version-button-group">
                <button
                  className="version-delete-button"
                  onClick={()=>handleDeletePhotos(deleteFile)}
                >
                  Delete
                </button>
                <button
                  className="version-cancel-button"
                  onClick={() =>
                    setDeletFile("")
                  }
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </section>
      )}

      <div className="media-popup-container">
        <div>
          <IoMdClose
            className="media-popup-icon"
            onClick={() => {
              handleDeleteTextBox(activePage.id, isMedia, "textBox",true);
              setIsMedia(false);
            }}
          />
        </div>
        <h2 className="finishes-types-popup-title">Your Media</h2>

        <div className="media-content">
          {!selectedFile && (
            <article
              className="media-item upload-media"
              onClick={handleUploadMediaClick}
            >
              <input
                type="file"
                ref={mediaInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
                accept="image/jpeg, image/png, image/jpg"
              />
              <div className="add-new-media" aria-label="Add New Quotation">
                <GoPlus className="add-new-media-icon" />
              </div>
              <p
                className="upload-media-text"
                style={{ color: sizeErr ? "red" : "" }}
              >
                {sizeErr ? "File size exceeds 1 MB" : "Upload Here"}
              </p>
            </article>
          )}

          {selectedFile && (
            <article
              className={`media-item uploaded-media`}
              onClick={handleUploadMediaClick}
            >
              <img
                src={URL.createObjectURL(selectedFile)}
                alt="media file"
                className="media-image"
              />
              <div className="uploaded-file-btns">
                <button onClick={handleUpload}>Upload</button>
                <MdDelete
                  className="uploaded-file-delete-icon"
                  onClick={() => setSelectedFile("")}
                />
              </div>
            </article>
          )}

          {photos &&
            photos.map((photo, photoIndex) => (
              <figure
                key={photoIndex}
                className={`media-item ${deleteFile === photo?'deleting-media-item':""}`}
                
              >
                 <MdDelete
                  className="file-delete-icon"
                  onClick={() =>
                    setDeletFile(photo)
                  }
                  
                />
                <img src={photo} alt="Media images" className="media-image" onClick={() => handleSelectPhoto(photo)}/>
              </figure>
            ))}
        </div>
      </div>
    </section>
  );
}

export default PageEditorMedia;
