import AXIOS from "../../axios";
import {
  setAuth,
  setUserRole,
  setAdminRole,
  setDesignerCount,
  setProfileImg,
  isMobileScreen,
  setRefferalDays,
  setRefferedByNames,
  setCurrentAccountType,
  setOrganizationID,
  setOrganizationRole,
  setIsOrganization,
} from "../actions/refreshToken.action";

export const refreshToken = () => async (dispatch) => {
  // if (window.location.pathname.startsWith("/final/interioverse-quotation/")) {
  //   console.log("helo");
    
  //   return; // Exit the thunk early if the pathname includes "final"
  // }

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  try {
    // Simulate a delay of 5 milliseconds before sending the request
    await delay(5);

    const response = await AXIOS.post(
      "/refresh-token",
      {},
      { withCredentials: true }
    );
    if (response.status === 200) {
      dispatch(setAuth(true));

      // Fetch user roles
      const roleResponse = await AXIOS.get("/user/user-role", {
        withCredentials: true,
      });
      if (roleResponse.status === 200) {
        const {
          role,
          admin_role: adminRole,
          countdown: count,
          profileImg,
          referredByNames,
          totalReferralDays,
          currentAccountType,
          organizationID,
          organizationRole,
          isOrganization
        } = roleResponse.data;

        dispatch(setUserRole(role));
        dispatch(setAdminRole(adminRole));
        dispatch(setCurrentAccountType(currentAccountType));
        dispatch(setOrganizationRole(organizationRole));
        dispatch(setOrganizationID(organizationID));
        dispatch(setIsOrganization(isOrganization));
        dispatch(setDesignerCount(adminRole === "Designer" ? count : 0));
        dispatch(setProfileImg(profileImg||""))
        dispatch(setRefferalDays(totalReferralDays || 0))
        dispatch(setRefferedByNames(referredByNames || []))

        if(window.innerWidth < 950){
          dispatch(isMobileScreen(true))
        }
      }
    } else {
      dispatch(setAuth(false));
    }
  } catch (error) {
    dispatch(setAuth(false));
  }
};
