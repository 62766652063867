// src/redux/reducers/authReducer.js
import {
  SET_AUTH,
  SET_USER_ROLE,
  SET_ADMIN_ROLE,
  SET_DESIGNER_COUNT,
  SET_PROFILE_IMG,
  SET_REFFERED_BY_NAMES,
  SET_REFFERAL_DAYS,
  SET_CURRENT_ACCOUNT_TYPE,
  SET_ORGANIZATION_ID,
  SET_ORGANIZATION_ROLE,
  SET_IS_ORGANIZATION
} from "../actions/refreshToken.action";

const initialState = {
  isAuthenticated: false,
  role: null,
  adminRole: null,
  profileImg: "",
  count: null,
  names: [],
  days: null,
  organizationRole: "",
  currentAccountType: "",
  organizationID: "",
  isOrganization:""
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case SET_USER_ROLE:
      return {
        ...state,
        role: action.payload,
      };
    case SET_ADMIN_ROLE:
      return {
        ...state,
        adminRole: action.payload,
      };
    case SET_CURRENT_ACCOUNT_TYPE:
      return {
        ...state,
        currentAccountType: action.payload,
      };
    case SET_ORGANIZATION_ID:
      return {
        ...state,
        organizationID: action.payload,
      };
    case SET_ORGANIZATION_ROLE:
      return {
        ...state,
        organizationRole: action.payload,
      };
      case SET_IS_ORGANIZATION:
        return {
          ...state,
          isOrganization: action.payload,
        };
    case SET_PROFILE_IMG:
      return {
        ...state,
        profileImg: action.payload,
      };
    case SET_DESIGNER_COUNT:
      return {
        ...state,
        count: action.payload,
      };
    case SET_REFFERED_BY_NAMES:
      return {
        ...state,
        names: action.payload,
      };
    case SET_REFFERAL_DAYS:
      return {
        ...state,
        days: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
