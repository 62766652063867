// src/redux/actions/refreshToken.action.js
export const SET_AUTH = 'SET_AUTH';
export const SET_USER_ROLE = 'SET_USER_ROLE';
export const SET_ADMIN_ROLE = 'SET_ADMIN_ROLE';
export const SET_PROFILE_IMG = 'SET_PROFILE_IMG';
export const SET_DESIGNER_COUNT = 'SET_DESIGNER_COUNT';
export const SET_SIGNUP = 'SET_SIGNUP'
export const SET_LOGIN = 'SET_LOGIN'
export const SET_IS_CLOSE = 'SET_IS_CLOSE'
export const SET_MOBILE_SCREEN = 'SET_MOBILE_SCREEN'
export const SET_REFFERED_BY_NAMES = "SET_REFFERED_BY_NAMES"
export const SET_REFFERAL_DAYS = "SET_REFFERAL_DAYS"
export const SET_CURRENT_ACCOUNT_TYPE = "SET_CURRENT_ACCOUNT_TYPE"
export const SET_ORGANIZATION_ID = "SET_ORGANIZATION_ID"
export const SET_ORGANIZATION_ROLE = "SET_ORGANIZATION_ROLE"
export const SET_IS_ORGANIZATION= "SET_IS_ORGANIZATION"



export const setAuth = (auth) => ({
  type: SET_AUTH,
  payload: auth,
});

export const setUserRole = (role) => ({
  type: SET_USER_ROLE,
  payload: role,
});

export const setAdminRole = (adminRole) => ({
  type: SET_ADMIN_ROLE,
  payload: adminRole,
});

export const setCurrentAccountType = (currentAccountType) => ({
  type: SET_CURRENT_ACCOUNT_TYPE,
  payload: currentAccountType,
});


export const setOrganizationID = (organizationID) => ({
  type: SET_ORGANIZATION_ID,
  payload: organizationID,
});

export const setOrganizationRole = (organizationRole) => ({
  type: SET_ORGANIZATION_ROLE,
  payload: organizationRole,
});

export const setIsOrganization = (isOrganization) => ({
  type: SET_IS_ORGANIZATION,
  payload: isOrganization,
});

export const setProfileImg = (profileImg) => ({
  type: SET_PROFILE_IMG,
  payload: profileImg,
});

export const setDesignerCount= (count) => ({
  type: SET_DESIGNER_COUNT,
  payload: count,
});

export const setSignup = (isOpen) => ({
  type: SET_SIGNUP,
  payload: isOpen,
});

export const setLogin = (isLogin) => ({
  type: SET_LOGIN,
  payload: isLogin,
});


export const saveTemplate= (isClose) => ({
    type: SET_IS_CLOSE,
    payload: isClose,
  });

  export const isMobileScreen= (isMobile) => ({
    type: SET_MOBILE_SCREEN,
    payload: isMobile,
  });

  export const setRefferedByNames = (names) => ({
    type: SET_REFFERED_BY_NAMES,
    payload: names,
  });

  export const setRefferalDays = (days) => ({
    type: SET_REFFERAL_DAYS,
    payload: days,
  });

