import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import AXIOS from "../../../services/axios";
import "./viewVersionsInProfile.css";
import { formatIndianNumber } from "../../../services/reusableCodes";

function ViewVersionsInProfile({ setIsPopUp, project }) {
  const [activeVersion, setActiveVersion] = useState(project.versions[0]);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const handleContactInterioverse = async (e) => {
    e.preventDefault();
    try {
      const response = await AXIOS.post(
        "/email/send-site-execution-request", // Update this URL to match your backend endpoint
        {
          projectId: project.project_id,
          versionName: activeVersion.version_name,
          user_price: activeVersion.quotation_price,
          interioverse_price: activeVersion.interioverse_price,
        },
        { withCredentials: true } // Ensures cookies are sent along with the request
      );

      if (response.status === 200) {
        setIsEmailSent(true);
        setTimeout(() => {
          setIsEmailSent(false);
        }, 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="edit-project">
      <div className="re-assigning-project-container">
        <IoMdClose
          className="close-popup"
          onClick={() =>
            setIsPopUp((prev) => ({
              ...prev,
              viewVersions: false,
            }))
          }
        />

        <div className="new-project-content">
          <h2 className="payment-title">View Versions</h2>
          <section className="profile-version-list">
            {project.versions.map((version, index) => (
              <div
                className={`view-version-item ${
                  activeVersion.version_name === version.version_name
                    ? "active-view-version-item"
                    : ""
                }`}
                key={index}
                onClick={() => setActiveVersion(version)}
              >
                <span>{version.version_name}</span>
              </div>
            ))}
          </section>
          <section className="re-assigning-project-section view-versions-section">
            <div className="profile-project-info">
              <h2 className="profile-project-title">{project.project_name}</h2>
              <p className="project-aassigned-to project-versions-info">
                Assigned to <p>{project.assigned_user.name}</p>
              </p>
              <p className="project-aassigned-to project-versions-info">
                Project Id <p>{project.project_id}</p>
              </p>
              <p className="project-aassigned-to project-versions-info">
                Created on <p>{project.created_on}</p>
              </p>
            </div>

            <div>
              <div className="version-pricing-container">
                <div className="version-pricing-item">
                  <div className="version-pricing-label">Your Quotation</div>
                  <div className="version-pricing-value profile-project-budget">
                    {"₹" + formatIndianNumber(activeVersion.quotation_price)}
                  </div>
                </div>
                <div>
                  <div className="version-pricing-item">
                    <div className="version-pricing-label">
                      Hire Interioverse at
                    </div>
                    <div className="version-pricing-value profile-project-budget">
                      {"₹" +
                        formatIndianNumber(Math.ceil(activeVersion.interioverse_price * 0.93))}
                    </div>
                  </div>
                  <div>
                    <button
                      className={`view-version-request-btn ${
                        isEmailSent ? "request-Successfull-btn" : ""
                      }`}
                   onClick={!isEmailSent && handleContactInterioverse}
                    >
                      { isEmailSent ?"Request sent successfully":"Contact Interioverse"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default ViewVersionsInProfile;
