import React, { useEffect, useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import A1 from "../../../assets/profile_avatars/A1.png";
import A2 from "../../../assets/profile_avatars/A2.png";
import A3 from "../../../assets/profile_avatars/A3.png";
import A4 from "../../../assets/profile_avatars/A4.png";
import A5 from "../../../assets/profile_avatars/A5.png";
import A6 from "../../../assets/profile_avatars/A6.png";
import "./teamMember.css";
import { GoPlus } from "react-icons/go";
import { IoMdClose } from "react-icons/io";
import AXIOS from "../../../services/axios";

function TeamMembers() {
  const [isAddingMember, setIsAddingMember] = useState(false);
  const [isVerifyMember, setIsVerifyMember] = useState({
    isVerified: false,
    userId: "",
    name: "",
    email: "",
    role: "",
    phone_number: "",
    profile_picture: "",
    projects_count: "",
  });
  const [memberInfo, setMemberInfo] = useState({
    userId: "",
    name: "",
    email: "",
    role: "",
    phone_number: "",
    profile_picture: "",
    projects_count: "",
  });
  const [message, setMessage] = useState("");
  const [memberId, setMemberId] = useState("");
  const [teamMembers, setTeamMembers] = useState({
    members: [],
    remainingSlab: "",
  });
  const TeamMembersImg = {
    A1: A1,
    A2: A2,
    A3: A3,
    A4: A4,
    A5: A5,
    A6: A6,
  };
  const [searchTerm, setSearchTerm] = useState("");
  const [removeMember, setRemoveMember] = useState({
    id: "",
    name: "",
  });

  const fetchTeamMembers = async (member) => {
    try {
      const response = await AXIOS.get("/organization/get-team-members", {
        withCredentials: true, // Ensure cookies are sent with the request
      });

      if (response.status === 200) {
        // Destructure the response data to get the team members and maxAllowedEmployees
        const { teamMembers, maxAllowedEmployees } = response.data;

        // Set the team members and calculate remainingSlab
        setTeamMembers((prev) => ({
          ...prev,
          members: teamMembers,
          remainingSlab: maxAllowedEmployees - teamMembers.length, // Calculate remaining slots
        }));

        if (member) {
          setMemberInfo((prev) => ({
            ...prev,
            userId: member.userId,
            name: member.name,
            email: member.email,
            role: member.role,
            phone_number: member.phone_number,
            profile_picture: member.profile_picture,
            projects_count: member.projects_count,
          }));
        } else {
          setMemberInfo((prev) => ({
            ...prev,
            ...teamMembers[0],
          }));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTeamMembers();
  }, []);

  const handleVerifyMember = async (e) => {
    e.preventDefault();
    try {
      if (!memberId) {
        setMessage("User ID is required");
        return;
      }

      const response = await AXIOS.post(
        "/organization/verify-adding-team-member", // Update this URL to match your backend endpoint
        { memberId },
        { withCredentials: true } // Ensures cookies are sent along with the request
      );

      if (response.status === 200) {
        const memberData = response.data.member;

        // Update state with isVerified set to true
        setIsVerifyMember((prevState) => ({
          ...prevState,
          isVerified: true,
          ...memberData,
        }));
      }
    } catch (error) {
      if (error.response) {
        if (
          error.response.status === 404 ||
          error.response.status === 409 ||
          error.response.status === 403
        ) {
          setMessage(error.response.data.message);
        }
      }
    }
  };

  const handleAddTeamMember = async (e) => {
    e.preventDefault();
    try {
      const response = await AXIOS.post(
        "/organization/add-member", // Update this URL to match your backend endpoint
        { userId: memberId },
        { withCredentials: true } // Ensures cookies are sent along with the request
      );

      if (response.status === 200) {
        fetchTeamMembers(isVerifyMember);

        setIsAddingMember(false);
        setIsVerifyMember({
          isVerified: false,
          userId: "",
          name: "",
          email: "",
          role: "",
          phone_number: "",
          profile_picture: "",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setMemberId(value);
    setMessage("");
    setIsVerifyMember({
      isVerified: false,
      userId: "",
      name: "",
      email: "",
      role: "",
      phone_number: "",
      profile_picture: "",
    });
  };

  const handleMemberInfo = (member) => {
    setMemberInfo((prev) => ({
      ...prev,
      ...member,
      projects_count: member.projects.length,
    }));
    setIsAddingMember(false);
    setMemberId("");
  };

  const handleRemoveMember = async (userId) => {
    try {
      const response = await AXIOS.delete("/organization/remove-team-member", {
        data: { userId }, // Sending data in the body
        withCredentials: true, // Ensure cookies are sent for authentication
      });

      if (response.status === 200) {
        fetchTeamMembers();
        setRemoveMember((prev) => ({
          ...prev,
          id: "",
          name: "",
        }))
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filteredMembers =
    teamMembers.members &&
    teamMembers.members.filter((member) =>
      member.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    

  return (
    <section className="profile-details team-members-details-container">
      {/* delet popup  */}
      {removeMember.id && (
        <section className="version-delete-popup">
          <div className="version-delete-container">
            <div className="version-delete-content">
              <p className="version-delete-label">{`Do you want to remove ${removeMember.name}?`}</p>
              <div className="version-button-group">
                <button
                  className="version-delete-button"
                  onClick={() => handleRemoveMember(removeMember.id)}
                >
                  Delete
                </button>
                <button
                  className="version-cancel-button"
                  onClick={() =>
                    setRemoveMember((prev) => ({
                      ...prev,
                      id: "",
                      name: "",
                    }))
                  }
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </section>
      )}

      <div className="team-members-info">
        <div className="order-history">
          <div className="order-search-bar team-members-search-bar">
            <IoSearchOutline className="order-search-icon" />
            <input
              type="text"
              placeholder="Search by Member Name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        <section className="team-members">
          {teamMembers.members &&
            filteredMembers.map((member, index) => (
              <figure key={index} className="team-member">
                <img
                  src={
                    TeamMembersImg[member.profile_picture] ||
                    TeamMembersImg["A1"]
                  }
                  alt="Team Member"
                  className={`team-member-image ${
                    memberInfo.name === member.name
                      ? "active-team-member-image"
                      : ""
                  }`}
                />
                <figcaption
                  className={`team-member-name ${
                    memberInfo.name === member.name
                      ? "active-team-member-name"
                      : ""
                  }`}
                  onClick={() => handleMemberInfo(member)}
                >
                  {member.name}
                </figcaption>
              </figure>
            ))}

          {teamMembers.remainingSlab > 0 &&
            Array.from({ length: teamMembers.remainingSlab }).map(
              (_, index) => (
                <figure key={index} className="team-member">
                  <div className="add-member">
                    <GoPlus className="add-member-icon" />
                  </div>
                  <figcaption
                    className="team-member-name"
                    onClick={() => {
                      setMemberInfo({
                        userId: "",
                        name: "",
                        email: "",
                        role: "",
                        phone_number: "",
                        profile_picture: "",
                        projects_count: "",
                      });
                      setIsAddingMember(true);
                    }}
                  >
                    Add
                  </figcaption>
                </figure>
              )
            )}
        </section>
      </div>

      {(isAddingMember || memberInfo) && (
        <section className="team-member-detail-container">
          <div className="member-detail">
            <IoMdClose
              className="member-info-close-icon"
              onClick={() => {
                setMemberInfo("");
                setIsAddingMember(false);
                setMemberId("");
              }}
            />
            <h2 className="member-name">
              {isAddingMember ? "Add New Member" : memberInfo.name}
            </h2>

            {!isAddingMember && memberInfo && (
              <figure className="member-profile">
                <img
                  src={TeamMembersImg[memberInfo.profile_picture] || A1}
                  alt="member"
                  className="member-image"
                />
                <figcaption className="member-info">
                  <p className="member-id">{isVerifyMember.name}</p>
                  <p className="member-id">{memberInfo.userId}</p>
                  <p className="member-email">{memberInfo.email}</p>
                  <p className="member-contact">{memberInfo.phone_number}</p>
                  <button
                    className="remove-member-btn"
                    onClick={() =>
                      setRemoveMember((prev) => ({
                        ...prev,
                        id: memberInfo.userId,
                        name: memberInfo.name,
                      }))
                    }
                  >
                    Remove
                  </button>
                </figcaption>
              </figure>
            )}

            {!isAddingMember && memberInfo && (
              <section className="member-quotations">
                <h3 className="member-quotation-title">
                  No of Projects: {memberInfo.projects_count}
                </h3>
              </section>
            )}

            {isAddingMember && (
              <section className="add-member-section">
                <label className="add-member-label">Enter Profile ID</label>

                <form
                  className="add-member-input-group"
                  onSubmit={(e) => {
                    if (!isVerifyMember.isVerified) {
                      handleVerifyMember(e);
                    } else {
                      e.preventDefault();
                    }
                  }}
                >
                  <input
                    type="text"
                    placeholder="Enter Profile ID"
                    className="add-member-profile-id-input"
                    spellCheck="false"
                    required
                    onChange={handleChange}
                    value={memberId}
                  />
                  <button
                    className={`added-member-verify-btn ${
                      isVerifyMember.isVerified
                        ? "added-member-verified-btn"
                        : ""
                    }`}
                    type="submit"
                  >
                    {isVerifyMember.isVerified ? "Verified" : "Verify"}
                  </button>
                </form>

                {message && <p className="error">{message}</p>}
              </section>
            )}

            {isAddingMember && isVerifyMember.isVerified && !message && (
              <figure className="member-profile">
                <img
                  src={
                    TeamMembersImg[isVerifyMember.profile_picture] ||
                    TeamMembersImg["A1"]
                  }
                  alt="member"
                  className="member-image"
                />
                <figcaption className="member-info">
                  <p className="member-id">{isVerifyMember.userId}</p>
                  <p>{isVerifyMember.name}</p>
                  <p className="member-email">{isVerifyMember.email}</p>
                  <p className="member-contact">
                    {isVerifyMember.phone_number}
                  </p>
                  <p className="member-joined">{`Role: ${isVerifyMember.role}`}</p>
                </figcaption>
              </figure>
            )}

            {isAddingMember && isVerifyMember.isVerified && !message && (
              <button
                className="added-member-btn"
                onClick={handleAddTeamMember}
              >
                Add Member
              </button>
            )}
          </div>
        </section>
      )}
    </section>
  );
}

export default TeamMembers;
