import React, { useEffect, useState } from "react";
import landingPageImg from "../../assets/Landing_page_picture.jpg";
import logo from "../../assets/logo.png";
import cover from "../../assets/payment_cover.jpg";
import { useNavigate } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import AXIOS from "../../services/axios";
import "./organizationPayment.css";
import { FiCheck } from "react-icons/fi";

const slot = [
  {
    "01-04": "829",
  },
  { "05-07": "1390" },
  { "08-10": "1890" },
  { "11-14": "2499" },
];

function OrganizationPayment({ formData, setIsPayment, setFormData }) {
  const navigate = useNavigate();
  const [slotPrice, setSlotPrice] = useState(slot[0]["01-04"]);

  const initPayment = (data) => {
    const options = {
      key: process.env.REACT_APP_RAZOR_KEY, // Replace with your live Razorpay API key
      amount: data.amount,
      currency: data.currency,
      name: "Interioverse Design Pvt. Ltd.", // Update with your subscription plan name
      description: "Subscription Payment",
      image: logo,
      order_id: data.id,
      handler: async (response) => {
        try {
          const verifyUrl = "/payment/verify"; // Adjust endpoint as per your backend configuration
          const { data, status } = await AXIOS.post(verifyUrl, response);
          if (status === 200) {
            const response = await AXIOS.post("/order/create-order");
            navigate("/");
          }
        } catch (error) {
          console.log(error);
        }
      },
      theme: {
        color: "#3399cc",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const handlePayment = async () => {
    try {
      const orderUrl = "/payment/order"; // Adjust endpoint as per your backend configuration
      const { data } = await AXIOS.post(orderUrl, { amount: 2990 }); // Amount in INR
      initPayment(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSlotInput = (price) => {
    setSlotPrice(price);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let maxAllowedEmployees;

    if (slot === "829") {
      maxAllowedEmployees = 4;
    } else if (slot === "1390") {
      maxAllowedEmployees = 7;
    } else if (slot === "1890") {
      maxAllowedEmployees = 10;
    } else if (slot === "2499") {
      maxAllowedEmployees = 14;
    }

    try {
      // Prepare form data to send
      const payload = {
        organizationName: formData.organizationName,
        registeredName: formData.registeredName,
        panCardNumber: formData.panCardNumber,
        companyEmail: formData.companyEmail,
        companyPhoneNumber: formData.companyPhoneNumber,
        baseLocation: formData.baseLocation,
        gst: formData.gst,
        monthlyQuotationVolume: formData.monthlyQuotationVolume,
        numberOfEmployees: formData.numberOfEmployees,
        maxAllowedEmployees,
      };

      // Make POST request to the backend
      const response = await AXIOS.post(
        "/organization/create-organization",
        payload
      );

      if (response.data.success) {
        // Reset the form
        setFormData({
          organizationName: "",
          registeredName: "",
          panCardNumber: "",
          companyEmail: "",
          companyPhoneNumber: "",
          baseLocation: "",
          gst: "",
          monthlyQuotationVolume: "",
          numberOfEmployees: "",
        });
        navigate("/");
      }
    } catch (error) {
      console.error("Error creating organization:", error);
    }
  };

  const handleIsPayment = () => {
    setIsPayment(false);
  };

  return (
    <section className="payment-section">
      <IoMdClose className="payment-close-icon" onClick={handleIsPayment} />
      <img src={landingPageImg} alt="Cover" className="cover-img" />
      <div className="payment-container">
        <h1 className="payment-title">Payment</h1>
        <div className="subscription-details">
          <div className="subscription-info">
            <h2 className="user-slot-heading">Select No of Users Slot</h2>
            <div className="user-slot-list">
              {slot.map((item, index) => {
                const [time, price] = Object.entries(item)[0]; // Extract key-value pair from the object
                return (
                  <div key={index} className="user-slot-item">
                    <div className="user-slot-details">
                      <label className="custom-input-checkbox">
                        <input
                          type="checkbox"
                          className="gst-field"
                          checked={slotPrice}
                          onChange={() => handleSlotInput(price)}
                        />
                        <span className="input-checkmark">
                          {slotPrice === price && (
                            <FiCheck className="check-icon" />
                          )}
                        </span>
                      </label>
                      <label
                        htmlFor={`slot-checkbox-${index}`}
                        className="user-slot-time"
                      >
                        {time}
                      </label>
                    </div>
                    <p className="user-slot-price">
                      {"₹" + price}{" "}
                      <span className="new-quotation-total-value">
                        (excl. of GST)
                      </span>
                    </p>
                  </div>
                );
              })}
              <p className="consent-message">
                *Please note, the amount will be automatically deducted from
                your bank account every month.
              </p>
            </div>
          </div>

          <div className="payment-method-section">
            <div className="payment-method-background">
              <img
                src={cover}
                alt=""
                className="payment-background-img organization-payment-background-img"
              />
            </div>
            <div className="payment-method-content">
              <h2 className="subscription-plan-title">
                Interioverse’s Enterprise
              </h2>
              <h6 className="subscription-price">
                {`₹${Number(slotPrice) + Math.ceil(slotPrice * 0.18)}/-`}{" "}
                <span className="price-duration">per month</span>
              </h6>

              <div className="payment-order-summary">
                <div className="organization-payment-summary-item">
                  <p className="payment-summary-label">Subtotal</p>
                  <p className="payment-summary-value">{`₹${slotPrice}`}</p>
                </div>
                <div className="organization-payment-summary-item">
                  <p className="payment-summary-label">GST</p>
                  <p className="payment-summary-value">{`₹${Math.ceil(
                    slotPrice * 0.18
                  )}`}</p>
                </div>
                <div className="payment-order-total">
                  <p className="payment-order-label">Order Total</p>
                  <p className="payment-order-value">{`₹${
                    Number(slotPrice) + Math.ceil(slotPrice * 0.18)
                  }`}</p>
                </div>
              </div>

              <button
                onClick={handleSubmit}
                className="proceed-button organization-proceed-button"
              >
                Proceed
              </button>
              <p className="consent-message">
                *By signing up or placing an order, you're consenting to our{" "}
                <span
                  className="privacy-policy"
                  onClick={() => navigate("/privacy-policy")}
                >
                  privacy policy.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OrganizationPayment;
