// RoutesWithScrollTop.js
import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { privateRoutes, publicRoutes } from "./Routes";
import Loading from "../components/loading/loading";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";

function RoutesWithScrollTop({ loading }) {
  const { pathname } = useLocation();
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const adminRole = useSelector((state) => state.auth.adminRole);
  const currentAccountType = useSelector(
    (state) => state.auth.currentAccountType
  );
  const organizationRole = useSelector((state) => state.auth.organizationRole);
  const isOrganization = useSelector((state) => state.auth.isOrganization);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      {!pathname.startsWith("/final-quotation") &&
        !pathname.startsWith("/final/interioverse-quotation") && <Header />}
      <main style={{ flex: 1 }}>
        <Routes>
          {publicRoutes.map(({ path, element }, index) => {
            if (path === "/membership") {
              if (
                currentAccountType === "organization" &&
                organizationRole !== "owner"
              ) {
                return (
                  <Route
                    key={path + "_" + index}
                    path={path}
                    element={<Navigate to="/" replace />}
                  />
                );
              }
            }

            return (
              <Route key={path + "_" + index} path={path} element={element} />
            );
          })}

          {privateRoutes.map(({ path, element }, index) => {
            if (
              path === "/new-project/:projectId/:version" ||
              path === "/quotation-template" ||
              path === "/my-quotation/:projectId/:version" ||
              path === "/interioverse-quotation/:projectId/:version" ||
              path === "/download-quotation/:projectId/:version" ||
              path === "/my-orders" ||
              path === "/book-service" ||
              path === "/payment"
            ) {
              if ( currentAccountType !== "organization" && adminRole !== "Designer") {
                return (
                  <Route
                    key={path + "_" + index}
                    path={path}
                    element={<Navigate to="/" replace />}
                  />
                );
              }
            }

           

            if (
              path === "/team-members" ||
              path === "/team-projects" || 
              path === "/my-templates" ||
              path === "/quotation-template/:cityName"
            ) {
              if (
                currentAccountType !== "organization" ||
                organizationRole !== "owner"
              ) {
                return (
                  <Route
                    key={path + "_" + index}
                    path={path}
                    element={<Navigate to="/" replace />}
                  />
                );
              }
            }

            if (
              path === "/quotation-template"
            ) {
              if (
                currentAccountType === "organization" &&
                organizationRole !== "owner"
              ) {
                return (
                  <Route
                    key={path + "_" + index}
                    path={path}
                    element={<Navigate to="/" replace />}
                  />
                );
              }
            }

            return (
              <Route
                key={path + "_" + index}
                path={path}
                element={isAuth ? element : <Navigate to="/" replace />}
              />
            );
          })}
        </Routes>
      </main>
      {!pathname.startsWith("/final-quotation") &&
        !pathname.startsWith("/final/interioverse-quotation") && <Footer />}
    </div>
  );
}

export default RoutesWithScrollTop;
